import { useEffect, useState } from 'react'
import _ from 'lodash'

import { commonUtils } from 'utils'
import useQuickSearchPage from 'hooks/clients/useQuickSearchPage'
import { IClientDetails, IHeartAndVascularDetails, IQuickSearch } from 'interfaces/quickSearch.interface'

const useLifestyleDetails = () => {
  const [
    loading,
    // setLoading
  ] = useState(false)

  const clientDetails: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails
  const customerFirstName = clientDetails?.firstLife?.firstName
  const customerSecondName = clientDetails?.secondLife?.firstName
  const enterMedicalDetailsOfCustomerFirst = clientDetails?.firstLife?.enterMedicalDetails
  const enterMedicalDetailsOfCustomerSecond = clientDetails?.secondLife?.enterMedicalDetails && clientDetails?.isSecondLife

  const localData: IHeartAndVascularDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').medicalDetails?.heartAndVascularDetails
  const [formData, setFormData] = useState({
    customerFirst: {
      hadHeartAttack: localData?.customerFirst?.hadHeartAttack,
      heartAttackRequiredHospitalisation: localData?.customerFirst?.heartAttackRequiredHospitalisation,
      heartAttackDateOfDiagnosis: localData?.customerFirst?.heartAttackDateOfDiagnosis,
      hadSurgeryForHeartCondition: localData?.customerFirst?.hadSurgeryForHeartCondition,
      hadHeartOrArteryBypass: localData?.customerFirst?.hadHeartOrArteryBypass,
      bypassSurgeryDate: localData?.customerFirst?.bypassSurgeryDate,
      hadStentInserted: localData?.customerFirst?.hadStentInserted,
      stentSurgeryDate: localData?.customerFirst?.stentSurgeryDate,
      hadValveReplacement: localData?.customerFirst?.hadValveReplacement,
      valveReplacementSurgeryDate: localData?.customerFirst?.valveReplacementSurgeryDate,
      hadPaceMakerOrICDFitted: localData?.customerFirst?.hadPaceMakerOrICDFitted,
      pacemakerOrICDSurgeryDate: localData?.customerFirst?.pacemakerOrICDSurgeryDate,
      isDiagnosedWithIschaemicHeartDisease: localData?.customerFirst?.isDiagnosedWithIschaemicHeartDisease,
      ischaemicHeartDiseaseDateOfDiagnosis: localData?.customerFirst?.ischaemicHeartDiseaseDateOfDiagnosis,
      isDiagnosedWithAngina: localData?.customerFirst?.isDiagnosedWithAngina,
      anginaDateOfDiagnosis: localData?.customerFirst?.anginaDateOfDiagnosis,
      isDiagnosedWithPeripheralVascularDisease: localData?.customerFirst?.isDiagnosedWithPeripheralVascularDisease,
      peripheralVascularDiseaseDateOfDiagnosis: localData?.customerFirst?.peripheralVascularDiseaseDateOfDiagnosis,
    },
    customerSecond: {
      hadHeartAttack: localData?.customerSecond?.hadHeartAttack,
      heartAttackRequiredHospitalisation: localData?.customerSecond?.heartAttackRequiredHospitalisation,
      heartAttackDateOfDiagnosis: localData?.customerSecond?.heartAttackDateOfDiagnosis,
      hadSurgeryForHeartCondition: localData?.customerSecond?.hadSurgeryForHeartCondition,
      hadHeartOrArteryBypass: localData?.customerSecond?.hadHeartOrArteryBypass,
      bypassSurgeryDate: localData?.customerSecond?.bypassSurgeryDate,
      hadStentInserted: localData?.customerSecond?.hadStentInserted,
      stentSurgeryDate: localData?.customerSecond?.stentSurgeryDate,
      hadValveReplacement: localData?.customerSecond?.hadValveReplacement,
      valveReplacementSurgeryDate: localData?.customerSecond?.valveReplacementSurgeryDate,
      hadPaceMakerOrICDFitted: localData?.customerSecond?.hadPaceMakerOrICDFitted,
      pacemakerOrICDSurgeryDate: localData?.customerSecond?.pacemakerOrICDSurgeryDate,
      isDiagnosedWithIschaemicHeartDisease: localData?.customerSecond?.isDiagnosedWithIschaemicHeartDisease,
      ischaemicHeartDiseaseDateOfDiagnosis: localData?.customerSecond?.ischaemicHeartDiseaseDateOfDiagnosis,
      isDiagnosedWithAngina: localData?.customerSecond?.isDiagnosedWithAngina,
      anginaDateOfDiagnosis: localData?.customerSecond?.anginaDateOfDiagnosis,
      isDiagnosedWithPeripheralVascularDisease: localData?.customerSecond?.isDiagnosedWithPeripheralVascularDisease,
      peripheralVascularDiseaseDateOfDiagnosis: localData?.customerSecond?.peripheralVascularDiseaseDateOfDiagnosis,
    },
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const { handleNextFormNavigation } = useQuickSearchPage()

  const submitData = () => {
    const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
    const newState = {
      ...localStorageData,
      medicalDetails: {
        ...localStorageData.medicalDetails,
        heartAndVascularDetails: formData,
      },
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
  }

  const handleNext = () => {
    handleNextFormNavigation(submitData)
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  return {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  }
}

export default useLifestyleDetails
