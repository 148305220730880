import { useContext, useEffect, useState } from 'react'
import { Form, message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { FormsContext } from 'contexts'
import { commonUtils, eventManager } from 'utils'

import useGeneral from './useGeneral'
import { useGetCustomers, useGetPropertyInfo, useGetPreviousAddresses, useGetOccupants, useGetOtherResidents } from 'services/clients/clients.service'
import { getCustomersOptions } from 'utils/getCustomersOptions'

import { addEditPropertyMutation, deleteOccupantsMutation, deleteOtherResidentsMutation, deletePreviousAddressHistoryMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import {
  AddEditPropertyPayload,
  DeleteOccupantsPayload,
  DeleteOtherResidentsPayload,
  DeletePreviousAddressHistoryPayload,
} from 'services/shared/mutation.payload'

const useProperty = () => {
  const [form] = Form.useForm()
  const deletePreviousAddressHistory = useCustomMutation<DeletePreviousAddressHistoryPayload>({ mutation: deletePreviousAddressHistoryMutation })
  const deleteOccupants = useCustomMutation<DeleteOccupantsPayload>({ mutation: deleteOccupantsMutation })
  const deleteOtherResidents = useCustomMutation<DeleteOtherResidentsPayload>({ mutation: deleteOtherResidentsMutation, refetchQueries: ['getOtherResidents'] })
  const [showAddressDetails, setShowAddressDetails] = useState(false)
  const { propertyToEdit } = useContext(FormsContext)
  const addEditProperty = useCustomMutation<AddEditPropertyPayload>({ mutation: addEditPropertyMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetPropertyInfo(idNumber, propertyToEdit)
  const { previousAddresses } = useGetPreviousAddresses(idNumber, propertyToEdit)
  const { occupants } = useGetOccupants(idNumber, propertyToEdit)
  const { otherResidents } = useGetOtherResidents(idNumber, propertyToEdit)
  const { data: customersData } = useGetCustomers(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    purchasingPropertyToBeMortgaged: data?.purchasingPropertyToBeMortgaged,
    address: {
      formattedAddress: data?.address?.formattedAddress,
      address1: data?.address?.address1,
      address2: data?.address?.address2,
      town: data?.address?.town,
      county: data?.address?.county,
      country: data?.address?.country,
      postcode: data?.address?.postcode,
    },
    inResidence: data?.inResidence,
    residents: data?.residents,
    longTermResident: data?.longTermResident,
    registeredOnTitleDeeds: data?.registeredOnTitleDeeds,
    registeredOnTitleDeedsPeople: data?.registeredOnTitleDeedsPeople,
    ownershipType: data?.ownershipType,
    propertyValue: data?.propertyValue,
    purchaseDate: data?.purchaseDate,
    isPropertyCurrentlyMortgaged: data?.isPropertyCurrentlyMortgaged,
    tenureType: data?.tenureType,
    leasePeriod: data?.leasePeriod,
    isPropertyValuedInLastSixMonth: data?.isPropertyValuedInLastSixMonth,
    valuationDetails: data?.valuationDetails,
    propertyType: data?.propertyType,
    isPropertyAgeRestrictedOrShelteredHousing: data?.isPropertyAgeRestrictedOrShelteredHousing,
    sinkingFund: data?.sinkingFund,
    housingDescription: data?.housingDescription,
    annualServiceChargesGroundRent: data?.annualServiceChargesGroundRent,
    isExLocalAuthorityOrMODProperty: data?.isExLocalAuthorityOrMODProperty,
    propertyAuthorityDescription: data?.propertyAuthorityDescription,
    isPropertyBTL: data?.isPropertyBTL,
    isPropertyStandardAndFreeOfAnyIssue: data?.isPropertyStandardAndFreeOfAnyIssue,
    hasThatchedRoof: data?.hasThatchedRoof,
    isSteelFrameConstruction: data?.isSteelFrameConstruction,
    steelFrameDescription: data?.steelFrameDescription,
    isTimberFrameConstruction: data?.isTimberFrameConstruction,
    timberFrameDescription: data?.timberFrameDescription,
    isPropertyCloseToCommercialPremises: data?.isPropertyCloseToCommercialPremises,
    propertyCloseToCommercialPremisesDescription: data?.propertyCloseToCommercialPremisesDescription,
    isPropertyListed: data?.isPropertyListed,
    listedGrade: data?.listedGrade,
    hasPropertySingleSkinWall: data?.hasPropertySingleSkinWall,
    singleSkinWallPercentage: data?.singleSkinWallPercentage,
    hasElementOfFlatRoof: data?.hasElementOfFlatRoof,
    flatRoofElementPercentage: data?.flatRoofElementPercentage,
    hasMoreThanOneAcreOfLand: data?.hasMoreThanOneAcreOfLand,
    propertyLandAcreage: data?.propertyLandAcreage,
    isSubjectToAgriculturalTies: data?.isSubjectToAgriculturalTies,
    agriculturalTiesDetails: data?.agriculturalTiesDetails,
    usedForCommercialPurposes: data?.usedForCommercialPurposes,
    usedForCommercialPurposesDetails: data?.usedForCommercialPurposesDetails,
    hasSufferedNaturalDisaster: data?.hasSufferedNaturalDisaster,
    naturalDisasterDetails: data?.naturalDisasterDetails,
    naturalDisasterDate: data?.naturalDisasterDate,
    workTakenToCorrect: data?.workTakenToCorrect,
    solarPanels: data?.solarPanels,
    solarPanelDetails: data?.solarPanelDetails,
    propertyBuiltYear: data?.propertyBuiltYear,
    hasAnyPlanToMoveHomeInFuture: data?.hasAnyPlanToMoveHomeInFuture,
    moveToHomePeriod: data?.moveToHomePeriod,
    houseMoveReasons: data?.houseMoveReasons,
    plansToMoveToLaterLivingProperty: data?.plansToMoveToLaterLivingProperty,
    loanRepaymentOnHomeMove: data?.loanRepaymentOnHomeMove,
    hasAdultOccupantsLivingWith: data?.hasAdultOccupantsLivingWith,
    isPropertyHeldInTrust: data?.isPropertyHeldInTrust,
    whyIsPropertyHeldInTrust: data?.whyIsPropertyHeldInTrust,
    hasPropertySprayFoamInsulation: data?.hasPropertySprayFoamInsulation,
    sprayFoamInsulationDetails: data?.sprayFoamInsulationDetails,
    isPropertyCloseToElectricityPylon: data?.isPropertyCloseToElectricityPylon,
    electricityPylonDetails: data?.electricityPylonDetails,
    hasPropertyFloodedOrIsItInRiskZone: data?.hasPropertyFloodedOrIsItInRiskZone,
    floodingDetails: data?.floodingDetails,
  })
  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      address: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('address.formattedAddress', formattedAddress)
    form.setFieldValue('address.address1', address1)
    form.setFieldValue('address.address2', address2)
    form.setFieldValue('address.town', town)
    form.setFieldValue('address.county', county)
    form.setFieldValue('address.country', country)
    form.setFieldValue('address.postcode', postcode)
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  const customersOptions = getCustomersOptions(customersData, false)

  const handleSubmit = async () => {
    const { errors } = await addEditProperty({
      idNumber,
      propertyToEdit,
      values: {
        ...formData,
        propertyValue: Number(formData.propertyValue),
        leasePeriod: Number(formData.leasePeriod),
        sinkingFund: Number(formData.sinkingFund),
        annualServiceChargesGroundRent: Number(formData.annualServiceChargesGroundRent),
        singleSkinWallPercentage: Number(formData.singleSkinWallPercentage),
        flatRoofElementPercentage: Number(formData.flatRoofElementPercentage),
        propertyBuiltYear: Number(formData.propertyBuiltYear),
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddPreviousAddress = () => {
    eventManager.openPreviousAddressHistoryModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const addressDataSource =
    previousAddresses.map((addressHistory: any) => ({
      id: addressHistory._id,
      residents: customersOptions.find((customer: any) => customer.value === addressHistory.residents)?.label,
      address1: addressHistory.address.address1,
      town: addressHistory.address.town,
      country: addressHistory.address.country,
      postcode: addressHistory.address.postcode,
      dateMovedIn: addressHistory.dateMovedIn,
      dateMovedOut: addressHistory.dateMovedOut,
    })) || []

  const [selectedAddress, setSelectedAddress] = useState({})
  const displayAddressDeleteButton = !!Object.keys(selectedAddress).length
  const displayPreviousAddressEditButton = Object.keys(selectedAddress).length === 1

  const setSelectedAddressRows = (selectedRowObj: any) => {
    setSelectedAddress(selectedRowObj)
  }

  const handleDeletePreviousAddress = async () => {
    setLoading(true)
    const previousAddress_IdsToDelete = Object.keys(selectedAddress)
    const { errors } = await deletePreviousAddressHistory({ _ids: previousAddress_IdsToDelete, idNumber, propertyToEdit })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedAddress({})
  }

  const handleDeleteAddressModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeletePreviousAddress(),
      },
    )
  }

  const handleAddOccupant = () => {
    eventManager.openOccupantModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const occupantsDataSource: any =
    occupants.map((occupant: any) => ({
      id: occupant._id,
      name: occupant.name,
      age: occupant.age,
      relationshipWithCustomer: occupant.relationshipWithCustomer,
    })) || []

  const [selectedOccupants, setSelectedOccupants] = useState({})
  const displayOccupantDeleteButton = !!Object.keys(selectedOccupants).length
  const displayOccupantEditButton = Object.keys(selectedOccupants).length === 1

  const setSelectedOccupantsRows = (selectedRowObj: any) => {
    setSelectedOccupants(selectedRowObj)
  }

  const handleDeleteOccupants = async () => {
    setLoading(true)
    const occupants_IdsToDelete = Object.keys(selectedOccupants)
    const { errors } = await deleteOccupants({ _ids: occupants_IdsToDelete, idNumber, propertyToEdit })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedOccupants({})
  }

  const handleDeleteOccupantModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteOccupants(),
      },
    )
  }

  const handlePreviousAddressEditModal = () => {
    eventManager.openPreviousAddressHistoryModal(
      { _id: Object.keys(selectedAddress)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleOccupantEditModal = () => {
    eventManager.openOccupantModal(
      { _id: Object.keys(selectedOccupants)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleAddOtherResident = () => {
    eventManager.openOtherResidentModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const [selectedOtherResidents, setSelectedOtherResidents] = useState({})
  const displayOtherResidentsDeleteButton = !!Object.keys(selectedOtherResidents).length
  const displayOtherResidentEditButton = Object.keys(selectedOtherResidents).length === 1

  const setSelectedOtherResidentRows = (selectedRowObj: any) => {
    setSelectedOtherResidents(selectedRowObj)
  }

  const handleDeleteOtherResident = async () => {
    setLoading(true)
    const otherResidents_IdsToDelete = Object.keys(selectedOtherResidents)
    const { errors } = await deleteOtherResidents({ _ids: otherResidents_IdsToDelete, idNumber, propertyToEdit })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedOtherResidents({})
  }

  const handleDeleteOtherResidentModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteOtherResident(),
      },
    )
  }

  const handleOtherResidentEditModal = () => {
    eventManager.openOtherResidentModal(
      { _id: Object.keys(selectedOtherResidents)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const otherResidentsDataSource: any =
    otherResidents.map((otherResident: any) => ({
      id: otherResident._id,
      name: otherResident.name,
      age: otherResident.age,
      relationshipWithCustomer: otherResident.relationshipWithCustomer,
    })) || []

  const residentsOptions = customersOptions.concat(otherResidents.map((otherResident: any) => ({ label: otherResident.name, value: otherResident._id })) || [])

  const floodRiskResources = [
    {
      country: 'England',
      resourceLink: 'https://www.gov.uk/check-long-term-flood-risk?_ga=2.79543005.81577322.1644313272-15291669.1644313272',
    },
    {
      country: 'Wales',
      resourceLink: 'https://naturalresources.wales/splash?orig=%2fflooding&lang=cy',
    },
    {
      country: 'Scotland',
      resourceLink: 'https://www.sepa.org.uk/environment/water/flooding/',
    },
    {
      country: 'Northern Ireland',
      resourceLink: 'https://www.nidirect.gov.uk/articles/check-risk-flooding-your-area',
    },
  ]

  const floodRiskResourceLink = floodRiskResources.find((resource) => resource?.country === formData?.address?.country)?.resourceLink || ''

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    residentsOptions,
    handleAddPreviousAddress,
    addressDataSource,
    setSelectedAddressRows,
    displayAddressDeleteButton,
    handleDeleteAddressModal,
    handleAddOccupant,
    occupantsDataSource,
    displayOccupantDeleteButton,
    setSelectedOccupantsRows,
    handleDeleteOccupantModal,
    displayPreviousAddressEditButton,
    handlePreviousAddressEditModal,
    displayOccupantEditButton,
    handleOccupantEditModal,
    handleAddOtherResident,
    setSelectedOtherResidentRows,
    displayOtherResidentsDeleteButton,
    handleDeleteOtherResidentModal,
    displayOtherResidentEditButton,
    handleOtherResidentEditModal,
    otherResidentsDataSource,
    floodRiskResourceLink,
    form,
  }
}

export default useProperty
