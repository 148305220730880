import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import message from 'antd/lib/message'

import Forms from 'contexts/Forms'
import { useGetQuickSearchDataForClientDetails } from 'services/clients/clients.service'
import { commonUtils } from 'utils'
import { IClientDetails, IQuickSearch } from 'interfaces/quickSearch.interface'

const useClientDetailsTab = () => {
  const {
    quickSearch: { tabs, activeTab, setActiveTab, setTabs },
  } = useContext(Forms)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: initialDataLoading } = useGetQuickSearchDataForClientDetails(idNumber)
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    firstLife: {
      title: data?.customerFirst?.title,
      firstName: data?.customerFirst?.firstName,
      lastName: data?.customerFirst?.lastName,
      gender: data?.customerFirst?.gender,
      dateOfBirth: data?.customerFirst?.dateOfBirth,
      useAgeNextBirthday: data?.customerFirst?.useAgeNextBirthday || false,
      enterMedicalDetails: data?.customerFirst?.enterMedicalDetails,
    },
    secondLife: {
      title: data?.customerSecond?.title,
      firstName: data?.customerSecond?.firstName,
      lastName: data?.customerSecond?.lastName,
      gender: data?.customerSecond?.gender,
      dateOfBirth: data?.customerSecond?.dateOfBirth,
      useAgeNextBirthday: data?.customerSecond?.useAgeNextBirthday || false,
      enterMedicalDetails: data?.customerSecond?.enterMedicalDetails,
    },
    isSecondLife: data?.isSecondLife || false,
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    const localData: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails

    const initialData = {
      firstLife: {
        title: localData?.firstLife?.title || data?.customerFirst?.title,
        firstName: localData?.firstLife?.firstName || data?.customerFirst?.firstName,
        lastName: localData?.firstLife?.lastName || data?.customerFirst?.lastName,
        gender: localData?.firstLife?.gender || data?.customerFirst?.gender,
        dateOfBirth: localData?.firstLife?.dateOfBirth || data?.customerFirst?.dateOfBirth,
        useAgeNextBirthday: localData?.firstLife?.useAgeNextBirthday || data?.customerFirst?.useAgeNextBirthday || false,
        enterMedicalDetails: localData?.firstLife?.enterMedicalDetails || data?.customerFirst?.enterMedicalDetails || false,
      },
      secondLife: {
        title: localData?.secondLife?.title || data?.customerSecond?.title,
        firstName: localData?.secondLife?.firstName || data?.customerSecond?.firstName,
        lastName: localData?.secondLife?.lastName || data?.customerSecond?.lastName,
        gender: localData?.secondLife?.gender || data?.customerSecond?.gender,
        dateOfBirth: localData?.secondLife?.dateOfBirth || data?.customerSecond?.dateOfBirth,
        useAgeNextBirthday: localData?.secondLife?.useAgeNextBirthday || data?.customerSecond?.useAgeNextBirthday || false,
        enterMedicalDetails: localData?.secondLife?.enterMedicalDetails || data?.customerSecond?.enterMedicalDetails || false,
      },
      isSecondLife: localData?.hasOwnProperty('isSecondLife') ? localData.isSecondLife : !!data?.customerSecond,
    }

    setFormData(initialData)
    setLoading(initialDataLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const handleCheckboxWithLabel = (name: string, e: any) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), name, e.target.checked))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const navigateNext = () => {
    const { clientDetails, medicalQuestions, propertyAndLanding, furtherInformation } = tabs
    const tabValues = [clientDetails, propertyAndLanding, furtherInformation]
    if (medicalQuestions) tabValues.splice(1, 0, medicalQuestions)
    const activeTabIndex = tabValues.indexOf(activeTab)
    setActiveTab(tabValues[activeTabIndex + 1])
  }

  const handleSubmit = async () => {
    const newState: IQuickSearch = {
      ...JSON.parse(localStorage.getItem('quickSearch') || '{}'),
      clientDetails: formData,
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
    navigateNext()
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleMedicalQuestionsTabChange = (e: any) => {
    handleAntdFormItemChange(e)

    const { name, value } = e.target
    const isFirst = name.includes('firstLife')
    const isSecond = name.includes('secondLife')

    const firstMedDetails = isFirst ? value === 'true' : formData.firstLife.enterMedicalDetails
    const secondMedDetails = isSecond ? value === 'true' : formData.secondLife.enterMedicalDetails

    const displayMedicalQuestions = firstMedDetails || secondMedDetails

    if (displayMedicalQuestions) {
      setTabs((prevState: any) => ({
        ...prevState,
        medicalQuestions: 'MEDICAL QUESTIONS',
      }))
    } else {
      const { medicalQuestions, ...restTabs } = tabs
      setTabs(restTabs)
    }
  }

  return {
    loading,
    flattenedFormData,
    handleCheckboxWithLabel,
    handleAntdFormItemChange,
    handleMedicalQuestionsTabChange,
    handleSubmit,
    onFinishFailed,
  }
}

export default useClientDetailsTab
