import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'

import useGeneral from './useGeneral'
import { useGetCaseOverviewInfoForWarnings, useGetPropertiesInfoForWarnings, useGetSuitabilityAndNextStepsInfo } from 'services/clients/clients.service'
import { generateWarnings } from 'pages/ClientDetails/DetailedForm/Forms/SuitabilityAndNextSteps/SuitabilityAndNextSteps.helper'
import { addEditSuitabilityAndNextStepsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditSuitabilityAndNextStepsPayload } from 'services/shared/mutation.payload'

const useSuitabilityAndNextSteps = () => {
  const [form] = Form.useForm()
  const addEditSuitabilityAndNextSteps = useCustomMutation<AddEditSuitabilityAndNextStepsPayload>({
    mutation: addEditSuitabilityAndNextStepsMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { caseOverview } = useGetCaseOverviewInfoForWarnings(idNumber)
  const { property } = useGetPropertiesInfoForWarnings(idNumber)
  const { data, loading: formInitialDataLoading } = useGetSuitabilityAndNextStepsInfo(idNumber)
  const [showAddressDetails, setShowAddressDetails] = useState(false)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    mortgageRecommendation: data?.mortgageRecommendation,
    transferringUnsecuredDebtsDetails: data?.transferringUnsecuredDebtsDetails,
    hasCustomerBeenToldAboutDebtTransferringRisks: data?.hasCustomerBeenToldAboutDebtTransferringRisks,
    undisclosedInformationDetails: data?.undisclosedInformationDetails,
    notesForBorrowing: data?.notesForBorrowing,
    hasRecommendedSolicitor: data?.hasRecommendedSolicitor,
    hasCustomerAcceptedRecommendedSolicitor: data?.hasCustomerAcceptedRecommendedSolicitor,
    estimatedSolicitorsFees: data?.estimatedSolicitorsFees,
    solicitorName: data?.solicitorName,
    solicitorAddress: {
      formattedAddress: data?.solicitorAddress?.formattedAddress,
      address1: data?.solicitorAddress?.address1,
      address2: data?.solicitorAddress?.address2,
      town: data?.solicitorAddress?.town,
      county: data?.solicitorAddress?.county,
      country: data?.solicitorAddress?.country,
      postcode: data?.solicitorAddress?.postcode,
    },
    adviceFee: data?.adviceFee,
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      solicitorAddress: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('solicitorAddress.formattedAddress', formattedAddress)
    form.setFieldValue('solicitorAddress.address1', address1)
    form.setFieldValue('solicitorAddress.address2', address2)
    form.setFieldValue('solicitorAddress.town', town)
    form.setFieldValue('solicitorAddress.county', county)
    form.setFieldValue('solicitorAddress.country', country)
    form.setFieldValue('solicitorAddress.postcode', postcode)
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  const handleSubmit = async () => {
    const { errors } = await addEditSuitabilityAndNextSteps({
      idNumber,
      values: {
        ...formData,
        estimatedSolicitorsFees: Number(formData.estimatedSolicitorsFees),
        adviceFee: Number(formData.adviceFee),
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const warnings = generateWarnings({ caseOverview, property })

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    warnings,
    form,
  }
}

export default useSuitabilityAndNextSteps
