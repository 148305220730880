import { useEffect, useState } from 'react'
import { message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'

import useGeneral from './useGeneral'
import { useGetCreditHistoryInfo, useGetCustomers } from 'services/clients/clients.service'
import { addEditCreditHistoryMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditCreditHistoryPayload } from 'services/shared/mutation.payload'

const useCreditHistory = () => {
  const addEditCreditHistory = useCustomMutation<AddEditCreditHistoryPayload>({
    mutation: addEditCreditHistoryMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading } = useGetCreditHistoryInfo(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const { data: customers } = useGetCustomers(idNumber)
  const [formData, setFormData] = useState({
    customerFirst: {
      hasEverJudgementRegistered: data?.creditHistory?.hasEverJudgementRegistered,
      applicantAndAmountDetails: data?.creditHistory?.applicantAndAmountDetails,
      hasEverBeenBankrupt: data?.creditHistory?.hasEverBeenBankrupt,
      isBankruptcyFullyDischarged: data?.creditHistory?.isBankruptcyFullyDischarged,
      bankruptcyDischargeDate: data?.creditHistory?.bankruptcyDischargeDate,
      otherDetailsRegardingBankruptcy: data?.creditHistory?.otherDetailsRegardingBankruptcy,
      hasEverBeenLateForPayments: data?.creditHistory?.hasEverBeenLateForPayments,
      detailsAboutLatePayments: data?.creditHistory?.detailsAboutLatePayments,
      isStrugglingWithPayments: data?.creditHistory?.isStrugglingWithPayments,
    },
    customerSecond: {
      hasEverJudgementRegistered: data?.customerTwoCreditHistory?.hasEverJudgementRegistered,
      applicantAndAmountDetails: data?.customerTwoCreditHistory?.applicantAndAmountDetails,
      hasEverBeenBankrupt: data?.customerTwoCreditHistory?.hasEverBeenBankrupt,
      isBankruptcyFullyDischarged: data?.customerTwoCreditHistory?.isBankruptcyFullyDischarged,
      bankruptcyDischargeDate: data?.customerTwoCreditHistory?.bankruptcyDischargeDate,
      otherDetailsRegardingBankruptcy: data?.customerTwoCreditHistory?.otherDetailsRegardingBankruptcy,
      hasEverBeenLateForPayments: data?.customerTwoCreditHistory?.hasEverBeenLateForPayments,
      detailsAboutLatePayments: data?.customerTwoCreditHistory?.detailsAboutLatePayments,
      isStrugglingWithPayments: data?.customerTwoCreditHistory?.isStrugglingWithPayments,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({
      customerFirst: {
        ...newData.creditHistory,
      },
      customerSecond: {
        ...newData.customerSecondCreditHistory,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditCreditHistory({
      idNumber,
      values: formData,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    customers,
  }
}

export default useCreditHistory
