import { useState, useEffect } from 'react'
import { message } from 'antd'

import { useGetClientDataForTrackingQuery } from 'services/clients/clients.service'
import { commonHelper } from 'helpers'

import useTabsGeneral from './useTabsGeneral'
import { commonUtils } from 'utils'
import { addEditClientTrackingMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditClientTrackingPayload } from 'services/shared/mutation.payload'

const useTrackingTabContent = () => {
  const addEditClientTracking = useCustomMutation<AddEditClientTrackingPayload>({ mutation: addEditClientTrackingMutation })

  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: initialDataLoading } = useGetClientDataForTrackingQuery(idNumber)
  const [loading, setLoading] = useState(initialDataLoading)

  const [trackingFormData, setTrackingFormData] = useState({
    name: data?.customerFirst?.firstName,
    telephone: data?.customerFirst?.mobileTelephone,
    postcode: data?.properties?.[0]?.address?.postcode,
    lender: data?.tracking?.lender,
    amount: data?.tracking?.amount,
    value: data?.properties?.[0]?.propertyValue,
    applicationDate: data?.tracking?.applicationDate,
    valuation: data?.tracking?.valuation,
    offer: data?.tracking?.offer,
    solicitor: data?.tracking?.solicitor,
    feeAuthoSentDate: data?.tracking?.feeAuthoSentDate,
    solVisit: data?.tracking?.solVisit,
    completionDate: data?.tracking?.completionDate,
    comission: data?.tracking?.comission,
    adviceFee: data?.suitabilityAndNextSteps?.adviceFee,
    LPA: data?.tracking?.LPA,
    referComms: data?.tracking?.referComms,
    referer: data?.tracking?.referer,
  })

  useEffect(() => {
    setLoading(initialDataLoading)
    setTrackingFormData({
      name: data?.customerFirst?.firstName,
      telephone: data?.customerFirst?.mobileTelephone,
      postcode: data?.properties?.[0]?.address?.postcode,
      lender: data?.tracking?.lender,
      amount: data?.tracking?.amount,
      value: data?.properties?.[0]?.propertyValue,
      applicationDate: data?.tracking?.applicationDate,
      valuation: data?.tracking?.valuation,
      offer: data?.tracking?.offer,
      solicitor: data?.tracking?.solicitor,
      feeAuthoSentDate: data?.tracking?.feeAuthoSentDate,
      solVisit: data?.tracking?.solVisit,
      completionDate: data?.tracking?.completionDate,
      comission: data?.tracking?.comission,
      adviceFee: data?.suitabilityAndNextSteps?.adviceFee,
      LPA: data?.tracking?.LPA,
      referComms: data?.tracking?.referComms,
      referer: data?.tracking?.referer,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setTrackingFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditClientTracking({
      idNumber,
      values: {
        ...trackingFormData,
        telephone: String(trackingFormData.telephone),
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useTabsGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  return {
    handleAntdFormItemChange,
    trackingFormData,
    loading,
    onFinishFailed,
    onSubmit,
    setSaveType,
  }
}

export default useTrackingTabContent
