import { BLOCKS, Block, INLINES, Inline } from '@contentful/rich-text-types'

// const contentfulDefaultOptions = {
//   renderNode: {
//     [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
//       // Ensure node is of type Block
//       if (node.nodeType === BLOCKS.EMBEDDED_ASSET) {
//         return <img src={`https:${node.data.target.fields.file.url}`} alt={node.data.target.fields.title} />
//       }
//     },
//     [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
//       // Ensure node is of type Inline
//       if (node.nodeType === INLINES.HYPERLINK) {
//         return (
//           <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
//             {children}
//           </a>
//         )
//       }
//     },
//     [BLOCKS.HEADING_3]: (node: Block | Inline, children: React.ReactNode) => <h3 style={{ margin: '20px 0 8px 0' }}>{children}</h3>,
//     [BLOCKS.UL_LIST]: (node: Block | Inline, children: React.ReactNode) => <ul style={{ margin: '8px 0 8px 24px' }}>{children}</ul>,
//     [BLOCKS.OL_LIST]: (node: Block | Inline, children: React.ReactNode) => <ol style={{ margin: '8px 0 8px 24px' }}>{children}</ol>,
//     // ...other custom renderers
//   },
// }

const hTagStyles = {
  margin: '20px 0 8px 0',
}

const olTagStyles = {
  margin: '8px 0 8px 24px',
}

const ulTagStyles = {
  margin: '8px 0 8px 24px',
}

const contentfulDefaultOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      // Ensure node is of type Block
      if (node.nodeType === BLOCKS.EMBEDDED_ASSET) {
        return <img src={`https:${node.data.target.fields.file.url}`} alt={node.data.target.fields.title} />
      }
    },
    [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
      // Ensure node is of type Inline
      if (node.nodeType === INLINES.HYPERLINK) {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      }
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <p className="contentful-content">{children}</p>
    },
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h1 style={hTagStyles}>{children}</h1>
    },
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h2 style={hTagStyles}>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h3 style={hTagStyles}>{children}</h3>
    },
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h4 style={hTagStyles}>{children}</h4>
    },
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h5 style={hTagStyles}>{children}</h5>
    },
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <h6 style={hTagStyles}>{children}</h6>
    },
    [BLOCKS.OL_LIST]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <ol style={olTagStyles}>{children}</ol>
    },
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: React.ReactNode) => {
      // Apply CSS class to each child without wrapping in new tags
      return <ul style={ulTagStyles}>{children}</ul>
    },
  },
}

export default contentfulDefaultOptions
