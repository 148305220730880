import { Dropdown, Modal, Button } from 'antd'

import useClientDetailsAction from 'hooks/clients/ClientDetails/useClientDetailsAction'
import { ActionsDots } from 'assets/images'

import { StyledActionsButtonsContainer, StyledActionsDots, StyledMenu, StyledItem } from './Actions.styles'
import Loading from 'components/Loading'
import LogoUploadModal from 'modals/LogoUploadModal'

interface ActionsProps {
  handleDelete: () => void
}

interface IGenerateMenuProps {
  handleDelete: () => void
  handleDownload: () => void
  handlePdfView: () => void
  loading: boolean
  showModal: boolean
  handleFileUpload: (file: File) => void
  closeModal: () => void
  handleDownloadModal: () => void
  fileName: string | null
  setFileName: React.Dispatch<React.SetStateAction<string | null>>
}

const generateMenu = ({
  handleDelete,
  handleDownload,
  handlePdfView,
  loading,
  showModal,
  handleFileUpload,
  closeModal,
  handleDownloadModal,
  fileName,
  setFileName,
}: IGenerateMenuProps) => (
  <StyledMenu>
    {/* <StyledItem>Send email</StyledItem> */}
    {/* <StyledItem onClick={handlePdfView}>See PDF Version</StyledItem> */}
    <StyledItem onClick={handleDownloadModal}>Download Fact Find {loading && <Loading />}</StyledItem>
    <StyledItem style={{ color: '#E73F3F' }} onClick={handleDelete}>
      Delete
    </StyledItem>
    <Modal
      title="Upload Logo"
      visible={showModal}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="download" type="primary" onClick={handleDownload}>
          Download
        </Button>,
      ]}
    >
      <LogoUploadModal setFileName={setFileName} fileName={fileName} loading={loading} handleFileUpload={handleFileUpload} />
    </Modal>
  </StyledMenu>
)

const Actions = ({ handleDelete }: ActionsProps) => {
  const { handleDownload, handlePdfView, loading, showModal, handleFileUpload, closeModal, handleDownloadModal, setFileName, fileName } =
    useClientDetailsAction()
  const menu = generateMenu({
    handleDelete,
    handleDownload,
    handlePdfView,
    loading,
    showModal,
    handleFileUpload,
    closeModal,
    handleDownloadModal,
    fileName,
    setFileName,
  })

  return (
    <Dropdown overlay={menu} overlayStyle={{ position: 'fixed' }} trigger={['click']}>
      <StyledActionsButtonsContainer>
        <StyledActionsDots src={ActionsDots} />
      </StyledActionsButtonsContainer>
    </Dropdown>
  )
}

export default Actions
