import { Form, Modal } from 'antd'

import { Styled2x2Grid, StyledWrapper } from './IncomeSourceModal.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import { incomeTypeOptions, whenWillIncomeChangeOptions } from './IncomeSourceModal.helper'
import useIncomeSourceModal from 'hooks/clients/Modals/useIncomeSourceModal'
import Loading from 'components/Loading/Loading'
import { PoundIcon } from 'assets/images'
import { yesNoOptions } from 'utils/commonOptions'

const IncomeSourceModal = () => {
  const { modalState, handleCancelClick, handleConfirmClick, formData, handleAntdFormItemChange, handleCustomSelectChange, loading, form, customersOptions } =
    useIncomeSourceModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Income"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="incomeOwner"
                rules={['isRequired']}
                type="select"
              />
              <CustomFormItem
                label="Type of Income *"
                width={'100%'}
                placeholder="Type of Income *"
                onChange={handleCustomSelectChange}
                options={incomeTypeOptions}
                name="incomeType"
                type="select"
                rules={['isRequired']}
              />
            </Styled2x2Grid>
            <CustomFormItem
              label="Monthly Net Income *"
              name="monthlyNetIncome"
              placeholder="Monthly Net Income"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
              onChange={handleAntdFormItemChange}
              type="number"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Annual Gross"
              name="annualGross"
              placeholder="Annual Gross"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
              onChange={handleAntdFormItemChange}
              type="number"
            />
            <CustomFormItem
              label="Will this amount change in the future? (excluding CPI/RPI) *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="willIncomeChange"
              value={formData.willIncomeChange}
              type="radio"
              rules={['isRequired']}
            />
            {formData.willIncomeChange && (
              <Styled2x2Grid>
                <CustomFormItem
                  label="When will it change?"
                  width={'100%'}
                  placeholder="When will it change?"
                  onChange={handleCustomSelectChange}
                  options={whenWillIncomeChangeOptions}
                  name="whenWillIncomeChange"
                  type="select"
                />
                <CustomFormItem
                  label="To what amount?"
                  name="incomeWillChangeTo"
                  placeholder="To what amount?"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
              </Styled2x2Grid>
            )}
            <CustomFormItem
              label="Description"
              placeholder="Description"
              value={formData.description}
              name="description"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default IncomeSourceModal
