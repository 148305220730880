import { useEffect, useState } from 'react'
import { message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { commonUtils, eventManager } from 'utils'

import useGeneral from './useGeneral'
import { useGetCustomers, useGetFuturePensions, useGetIncomeInfo, useGetIncomeSources } from 'services/clients/clients.service'
import { incomeTypeOptions, whenWillIncomeChangeOptions } from 'modals/IncomeSourceModal/IncomeSourceModal.helper'
import { pensionTypeOptions, whenWillPensionMatureOptions } from 'modals/FuturePensionModal/FuturePensionModal.helper'
import { addEditIncomeMutation, deleteFuturePensionsMutation, deleteIncomeSourcesMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditIncomePayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'

const useIncome = () => {
  const deleteIncomeSources = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({
    mutation: deleteIncomeSourcesMutation,
    refetchQueries: ['getIncomeSources'],
  })
  const deleteFuturePensions = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({
    mutation: deleteFuturePensionsMutation,
    refetchQueries: ['getFuturePensions'],
  })
  const addEditIncome = useCustomMutation<AddEditIncomePayload>({ mutation: addEditIncomeMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetIncomeInfo(idNumber)
  const { incomeSources } = useGetIncomeSources(idNumber)
  const { futurePensions } = useGetFuturePensions(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    customerFirst: {
      hasPensionAndNotTaking: data?.income?.hasPensionAndNotTaking,
      employmentStatus: data?.income?.employmentStatus,
      whenAreYouGoingToRetire: data?.income?.whenAreYouGoingToRetire,
      customerIncomeFateInEventOfTheirDeath: data?.income?.customerIncomeFateInEventOfTheirDeath,
      isHappyWithCurrentIncome: data?.income?.isHappyWithCurrentIncome,
      requiredAdditionalMonthlyIncome: data?.income?.requiredAdditionalMonthlyIncome,
    },
    customerSecond: {
      hasPensionAndNotTaking: data?.customerSecondIncome?.hasPensionAndNotTaking,
      employmentStatus: data?.customerSecondIncome?.employmentStatus,
      whenAreYouGoingToRetire: data?.customerSecondIncome?.whenAreYouGoingToRetire,
      customerIncomeFateInEventOfTheirDeath: data?.customerSecondIncome?.customerIncomeFateInEventOfTheirDeath,
      isHappyWithCurrentIncome: data?.customerSecondIncome?.isHappyWithCurrentIncome,
      requiredAdditionalMonthlyIncome: data?.customerSecondIncome?.requiredAdditionalMonthlyIncome,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)
  const { data: customers } = useGetCustomers(idNumber)
  const customersOptions = getCustomersOptions(customers, true)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    delete newData?.income?._id
    delete newData?.customerSecondIncome?._id
    setFormData({
      customerFirst: {
        ...newData.income,
      },
      customerSecond: {
        ...newData.customerSecondIncome,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), name, value))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditIncome({
      idNumber,
      values: formData,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddIncomeSource = () => {
    eventManager.openIncomeSourceModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const incomesDataSource =
    incomeSources.map((incomeSource: any) => ({
      id: incomeSource._id,
      incomeOwner: customersOptions.find((item: ILabelAndValue) => item.value === incomeSource.incomeOwner)?.label,
      incomeType: incomeTypeOptions?.find((item: ILabelAndValue) => item.value === incomeSource.incomeType)?.label,
      monthlyNetIncome: incomeSource.monthlyNetIncome,
      annualGross: incomeSource.annualGross,
      willIncomeChange: incomeSource.willIncomeChange,
      whenWillIncomeChange: whenWillIncomeChangeOptions?.find((item: ILabelAndValue) => item.value === incomeSource.whenWillIncomeChange)?.label,
      incomeWillChangeTo: incomeSource.incomeWillChangeTo,
    })) || []

  const [selectedIncomeSources, setSelectedIncomeSources] = useState({})
  const displayDeleteIncomeSourceBtn = !!Object.keys(selectedIncomeSources).length
  const displayIncomeSourceEditButton = Object.keys(selectedIncomeSources).length === 1

  const setIncomeSourceSelectedRows = (selectedRowObj: any) => {
    setSelectedIncomeSources(selectedRowObj)
  }

  const handleIncomeSourceDelete = async () => {
    setLoading(true)
    const incomeSources_IdToDelete = Object.keys(selectedIncomeSources)
    const { errors } = await deleteIncomeSources({ _ids: incomeSources_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedIncomeSources({})
  }

  const handleDeleteIncomeSourceModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleIncomeSourceDelete(),
      },
    )
  }

  const handleAddFuturePension = () => {
    eventManager.openFuturePensionModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const pensionsDataSource =
    futurePensions.map((futurePension: any) => ({
      id: futurePension._id,
      pensionOwner: customersOptions.find((item: ILabelAndValue) => item.value === futurePension.pensionOwner)?.label,
      pensionType: pensionTypeOptions?.find((item: ILabelAndValue) => item.value === futurePension.pensionType)?.label,
      whenWillPensionMature: whenWillPensionMatureOptions?.find((item: ILabelAndValue) => item.value === futurePension.whenWillPensionMature)?.label,
      pensionFundAmount: futurePension.pensionFundAmount,
      cashFromFund: futurePension.cashFromFund,
      monthlyIncomeFromPension: futurePension.monthlyIncomeFromPension,
    })) || []

  const [selectedPensions, setSelectedPensions] = useState({})
  const displayDeletePensionsBtn = !!Object.keys(selectedPensions).length
  const displayFuturePensionEditButton = Object.keys(selectedPensions).length === 1

  const setPensionsSelectedRows = (selectedRowObj: any) => {
    setSelectedPensions(selectedRowObj)
  }

  const handlePensionDelete = async () => {
    setLoading(true)
    const futurePensions_IdToDelete = Object.keys(selectedPensions)
    const { errors } = await deleteFuturePensions({ _ids: futurePensions_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedPensions({})
  }

  const handlePensionsModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handlePensionDelete(),
      },
    )
  }

  const handleIncomeSourceEditModal = () => {
    eventManager.openIncomeSourceModal(
      { _id: Object.keys(selectedIncomeSources)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleFuturePensionEditModal = () => {
    eventManager.openFuturePensionModal(
      { _id: Object.keys(selectedPensions)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleAddIncomeSource,
    incomesDataSource,
    displayDeleteIncomeSourceBtn,
    setIncomeSourceSelectedRows,
    handleDeleteIncomeSourceModal,
    handleAddFuturePension,
    pensionsDataSource,
    displayDeletePensionsBtn,
    setPensionsSelectedRows,
    handlePensionsModal,
    displayIncomeSourceEditButton,
    handleIncomeSourceEditModal,
    displayFuturePensionEditButton,
    handleFuturePensionEditModal,
    customers,
  }
}

export default useIncome
