import React from 'react'

import Loading from 'components/Loading'
import { FileInputContainer, FileInputLabel, FileName, StyledFileInput } from './LogoUploadModal.styles'

interface LogoUploadModalProps {
  fileName: string | null
  setFileName: React.Dispatch<React.SetStateAction<string | null>>
  loading: boolean
  handleFileUpload: (file: File) => void
}

const LogoUploadModal: React.FC<LogoUploadModalProps> = ({ setFileName, fileName, loading, handleFileUpload }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFileName(event.target.files[0].name)
      handleFileUpload(event.target.files[0])
    }
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <FileInputContainer>
          <p>Please note if you upload a logo that will be used in file header, if not the default PERC logo will be in the header</p>
          <StyledFileInput id="fileInput" type="file" onChange={handleFileChange} required />
          <FileInputLabel htmlFor="fileInput">Choose a file</FileInputLabel>
          {fileName && <FileName>Selected File: {fileName}</FileName>}
        </FileInputContainer>
        {loading && <Loading />}
      </div>
    </div>
  )
}

export default LogoUploadModal
