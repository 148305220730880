import { FormsContext } from 'contexts'
import { useContext } from 'react'
import { generateFormNavItems, initialGeneralTabItemsData } from '../staticData'
import { useGetClientCount } from 'services/clients/clients.service'

const useGeneral = ({ handleSubmit, setLoading }: { handleSubmit: any; setLoading: any }) => {
  const {
    // generalTabItems,
    setGeneralTabItems,
    detailedFormName,
    setDetailedFormName,
    antdActiveTabKey,
    setAntdActiveTabKey,
    // displayDetailForm,
    setDisplayDetailForm,
    setCustomerToEdit,
    breadcrumbItems,
    setBreadcrumbItems,
  } = useContext(FormsContext)

  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const {
    data: { customerQuantity },
  } = useGetClientCount(idNumber)

  const formNavItems = generateFormNavItems({ customerQuantity })

  const changeActiveForm = (direction?: string) => {
    // eslint-disable-next-line array-callback-return
    formNavItems.map((item: any, index: any) => {
      if (item.label === detailedFormName) {
        let dirInd: any
        if (direction !== 'next' && index === 0) {
          dirInd = index
        } else if (direction === 'next' && index === formNavItems.length - 1) {
          dirInd = index
          setAntdActiveTabKey(`${Number(antdActiveTabKey) + 1}`)
        } else {
          dirInd = direction === 'next' ? index + 1 : index - 1
        }
        setDisplayDetailForm(formNavItems[dirInd].displayForm)
        setDetailedFormName(formNavItems[dirInd].label)
        setBreadcrumbItems([...breadcrumbItems.slice(0, 1), { text: formNavItems[dirInd].label, key: dirInd + 1 }])

        const nextTabItem = initialGeneralTabItemsData.find((tabItem: any) => tabItem.label === formNavItems[dirInd].label)
        if (nextTabItem?.hasChildren) setGeneralTabItems(nextTabItem.children)
      }
    })
  }

  const handleNavigateBack = () => {
    if (antdActiveTabKey === '1') {
      changeActiveForm()
    } else {
      setAntdActiveTabKey(`${Number(antdActiveTabKey) - 1}`)
      setDisplayDetailForm(false)
    }
  }

  const handleNavigateNext = () => {
    if (antdActiveTabKey === '1') {
      changeActiveForm('next')
    } else {
      setAntdActiveTabKey(`${Number(antdActiveTabKey) + 1}`)
      setDisplayDetailForm(false)
    }
  }

  const handleSaveAndGoBack = async () => {
    setLoading(true)
    await handleSubmit()
    handleNavigateBack()
    setCustomerToEdit(1)
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    await handleSubmit()
    setLoading(false)
  }

  const handleSaveAndContinue = async () => {
    setLoading(true)
    await handleSubmit()
    if (customerQuantity === 2) {
      setCustomerToEdit(2)
    }
    handleNavigateNext()
    setLoading(false)
  }

  return {
    handleSaveAndGoBack,
    handleSave,
    handleSaveAndContinue,
    setLoading,
  }
}

export default useGeneral
