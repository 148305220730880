import { useEffect, useState } from 'react'
import { message } from 'antd'
import { useParams } from 'react-router-dom'

import { editUserIressDetailsMutation } from 'gql/user'
import { commonHelper } from 'helpers'
import { useCustomMutation } from 'services/shared/mutation'
import { EditUserIressDetailsPayload } from 'services/shared/mutation.payload'
import { useGetUserById } from 'services/users/users.service'
import { commonUtils } from 'utils'

const useIRESSDetailsContent = () => {
  const { userId } = useParams()
  const { user, loading: userLoading } = useGetUserById(userId as string)
  const editUserIressDetails = useCustomMutation<EditUserIressDetailsPayload>({
    mutation: editUserIressDetailsMutation,
  })
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState<any>({
    email: user.email,
    companyId: user.companyId,
    iressLoginId: user?.iressLoginId,
    iressPassword: user?.iressPassword,
    iressUserId: user?.iressUserId,
  })

  useEffect(() => {
    setLoading(userLoading)
    setFormData({
      email: user?.email,
      companyId: user?.companyId,
      iressLoginId: user?.iressLoginId,
      iressPassword: user?.iressPassword,
      iressUserId: user?.iressUserId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await editUserIressDetails({
      ...formData,
      _id: userId,
      iressLoginId: String(formData.iressLoginId),
      iressPassword: String(formData.iressPassword),
      iressUserId: Number(formData.iressUserId),
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  return {
    loading,
    formData,
    handleAntdFormItemChange,
    handleSubmit,
  }
}

export default useIRESSDetailsContent
