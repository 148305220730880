import { useEffect, useState } from 'react'
import _ from 'lodash'

import { commonUtils } from 'utils'
import useQuickSearchPage from 'hooks/clients/useQuickSearchPage'
import { IClientDetails, IQuickSearch, IStrokeDetails } from 'interfaces/quickSearch.interface'

const useStrokeDetails = () => {
  const [
    loading,
    // setLoading
  ] = useState(false)

  const clientDetails: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails
  const customerFirstName = clientDetails?.firstLife?.firstName
  const customerSecondName = clientDetails?.secondLife?.firstName
  const enterMedicalDetailsOfCustomerFirst = clientDetails?.firstLife?.enterMedicalDetails
  const enterMedicalDetailsOfCustomerSecond = clientDetails?.secondLife?.enterMedicalDetails && clientDetails?.isSecondLife

  const localData: IStrokeDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').medicalDetails?.strokeDetails

  const [formData, setFormData] = useState({
    customerFirst: {
      hadMajorStroke: localData?.customerFirst?.hadMajorStroke,
      firstMajorStrokeOccurrenceDate: localData?.customerFirst?.firstMajorStrokeOccurrenceDate,
      hadMiniStroke: localData?.customerFirst?.hadMiniStroke,
      miniStrokeRequiresPrescribedMedication: localData?.customerFirst?.miniStrokeRequiresPrescribedMedication,
      firstMiniStrokeOccurrenceDate: localData?.customerFirst?.firstMiniStrokeOccurrenceDate,
    },
    customerSecond: {
      hadMajorStroke: localData?.customerSecond?.hadMajorStroke,
      firstMajorStrokeOccurrenceDate: localData?.customerSecond?.firstMajorStrokeOccurrenceDate,
      hadMiniStroke: localData?.customerSecond?.hadMiniStroke,
      miniStrokeRequiresPrescribedMedication: localData?.customerSecond?.miniStrokeRequiresPrescribedMedication,
      firstMiniStrokeOccurrenceDate: localData?.customerSecond?.firstMiniStrokeOccurrenceDate,
    },
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const { handleNextFormNavigation } = useQuickSearchPage()

  const submitData = () => {
    const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
    const newState = {
      ...localStorageData,
      medicalDetails: {
        ...localStorageData.medicalDetails,
        strokeDetails: formData,
      },
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
  }

  const handleNext = () => {
    handleNextFormNavigation(submitData)
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  return {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  }
}

export default useStrokeDetails
