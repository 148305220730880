import { useEffect, useState } from 'react'
import _ from 'lodash'

import { commonUtils } from 'utils'
import useQuickSearchPage from 'hooks/clients/useQuickSearchPage'
import { ICancerDetails, IClientDetails, IQuickSearch } from 'interfaces/quickSearch.interface'

const useCancerDetails = () => {
  const [
    loading,
    // setLoading
  ] = useState(false)

  const clientDetails: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails
  const customerFirstName = clientDetails?.firstLife?.firstName
  const customerSecondName = clientDetails?.secondLife?.firstName
  const enterMedicalDetailsOfCustomerFirst = clientDetails?.firstLife?.enterMedicalDetails
  const enterMedicalDetailsOfCustomerSecond = clientDetails?.secondLife?.enterMedicalDetails && clientDetails?.isSecondLife

  const localData: ICancerDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').medicalDetails?.cancerDetails
  const [formData, setFormData] = useState({
    customerFirst: {
      isDiagnosedWithCancer: localData?.customerFirst?.isDiagnosedWithCancer,
      hasSkinCancer: localData?.customerFirst?.hasSkinCancer,
      skinCanerRequiredSurgery: localData?.customerFirst?.skinCanerRequiredSurgery,
      skinCanerRequiredChemotherapyOrRadiotherapy: localData?.customerFirst?.skinCanerRequiredChemotherapyOrRadiotherapy,
      skinCancerDiagnosisDate: localData?.customerFirst?.skinCancerDiagnosisDate,
    },
    customerSecond: {
      isDiagnosedWithCancer: localData?.customerSecond?.isDiagnosedWithCancer,
      hasSkinCancer: localData?.customerSecond?.hasSkinCancer,
      skinCanerRequiredSurgery: localData?.customerSecond?.skinCanerRequiredSurgery,
      skinCanerRequiredChemotherapyOrRadiotherapy: localData?.customerSecond?.skinCanerRequiredChemotherapyOrRadiotherapy,
      skinCancerDiagnosisDate: localData?.customerSecond?.skinCancerDiagnosisDate,
    },
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const { handleNextFormNavigation } = useQuickSearchPage()

  const submitData = () => {
    const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
    const newState = {
      ...localStorageData,
      medicalDetails: {
        ...localStorageData.medicalDetails,
        cancerDetails: formData,
      },
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
  }

  const handleNext = () => {
    handleNextFormNavigation(submitData)
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  return {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  }
}

export default useCancerDetails
