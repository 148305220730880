import { useEffect, useState } from 'react'
import { message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'

import useGeneral from './useGeneral'
import { useGetEstatePlanningInfo, useGetBeneficiaries, useGetCustomers } from 'services/clients/clients.service'
import { addEditEstatePlanningMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditEstatePlanningPayload } from 'services/shared/mutation.payload'
import { initialSubstituteDecisionMakerOptions } from 'pages/ClientDetails/DetailedForm/Forms/EstatePlanning/EstatePlanning.helper'

const useEstatePlanning = () => {
  const addEditEstatePlanning = useCustomMutation<AddEditEstatePlanningPayload>({
    mutation: addEditEstatePlanningMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { beneficiaries } = useGetBeneficiaries(idNumber)
  const { data, loading: formInitialDataLoading } = useGetEstatePlanningInfo(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    customerFirst: {
      isCustomerTakingSpecialistAdvice: data?.estatePlanning?.isCustomerTakingSpecialistAdvice,
      skipWillQuestions: data?.estatePlanning?.skipWillQuestions || false,
      hasWill: data?.estatePlanning?.hasWill,
      willNeedsUpdate: data?.estatePlanning?.willNeedsUpdate,
      willLastUpdatedDate: data?.estatePlanning?.willLastUpdatedDate,
      wouldLikeFamilyWillToBeOneOfObjectives: data?.estatePlanning?.wouldLikeFamilyWillToBeOneOfObjectives,
      amountOfWill: data?.estatePlanning?.amountOfWill,
      addAmountOfWillToLoan: data?.estatePlanning?.addAmountOfWillToLoan,
      howMuchAmountOfWillAddToLoan: data?.estatePlanning?.howMuchAmountOfWillAddToLoan,
      conversationDetailsAboutWill: data?.estatePlanning?.conversationDetailsAboutWill,
      skipPOAQuestions: data?.estatePlanning?.skipPOAQuestions || false,
      substituteDecisionMaker: data?.estatePlanning?.substituteDecisionMaker,
      powerOfAttorneyType: data?.estatePlanning?.powerOfAttorneyType,
      detailsAboutAttorney: data?.estatePlanning?.detailsAboutAttorney,
      hasCopyOfPOA: data?.estatePlanning?.hasCopyOfPOA,
      wouldLikePOAToBeOneOfObjectives: data?.estatePlanning?.wouldLikePOAToBeOneOfObjectives,
      amountForPOA: data?.estatePlanning?.amountForPOA,
      addAmountForPOAToLoan: data?.estatePlanning?.addAmountForPOAToLoan,
      howMuchAmountForPOAAddToLoan: data?.estatePlanning?.howMuchAmountForPOAAddToLoan,
      customerOpinionRegardingPOA: data?.estatePlanning?.customerOpinionRegardingPOA,
      skipFuneralQuestions: data?.estatePlanning?.skipFuneralQuestions || false,
      wouldLikeToCoverFuneralCost: data?.estatePlanning?.wouldLikeToCoverFuneralCost,
      hasPlanedOwnFuneral: data?.estatePlanning?.hasPlanedOwnFuneral,
      existingFuneralProvisionAvailable: data?.estatePlanning?.existingFuneralProvisionAvailable,
      projectedShortfall: data?.estatePlanning?.projectedShortfall,
      areBeneficiariesAbleToPayFuneralCostBeforeHouseSale: data?.estatePlanning?.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale,
      wouldLikeToMakeFuneralProvisionOneOfObjectives: data?.estatePlanning?.wouldLikeToMakeFuneralProvisionOneOfObjectives,
      howToPayPrePaidFuneralPlan: data?.estatePlanning?.howToPayPrePaidFuneralPlan,
      amountToBeBorrowedForFuneralPlan: data?.estatePlanning?.amountToBeBorrowedForFuneralPlan,
      funeralPlansDiscussTime: data?.estatePlanning?.funeralPlansDiscussTime,
      otherNotesRegardingFuneralArrangements: data?.estatePlanning?.otherNotesRegardingFuneralArrangements,
      inNeedOrAlreadyInCare: data?.estatePlanning?.inNeedOrAlreadyInCare,
      hasUndergoneNHSCareAssessment: data?.estatePlanning?.hasUndergoneNHSCareAssessment,
      isIndividualAlreadyInCareHome: data?.estatePlanning?.isIndividualAlreadyInCareHome,
      careHomeContactName: data?.estatePlanning?.careHomeContactName,
      careHomeContactNamePosition: data?.estatePlanning?.careHomeContactNamePosition,
      careHomeContactNumber: data?.estatePlanning?.careHomeContactNumber,
      careHomeAddress: data?.estatePlanning?.careHomeAddress,
      typeOfExistingCare: data?.estatePlanning?.typeOfExistingCare,
      existingCareHours: data?.estatePlanning?.existingCareHours,
      existingCareCost: data?.estatePlanning?.existingCareCost,
      requiredCareType: data?.estatePlanning?.requiredCareType,
      howLongDoesIndividualWishToRemainInOwnHome: data?.estatePlanning?.howLongDoesIndividualWishToRemainInOwnHome,
      howLikelyIndividualRemainInOwnHome: data?.estatePlanning?.howLikelyIndividualRemainInOwnHome,
      howLikelyIndividualMoveBackAndForthHomeToCare: data?.estatePlanning?.howLikelyIndividualMoveBackAndForthHomeToCare,
      timescales: data?.estatePlanning?.timescales,
      hasFullFinancialAssessmentUndertakenYet: data?.estatePlanning?.hasFullFinancialAssessmentUndertakenYet,
      estimatedNewCareCosts: data?.estatePlanning?.estimatedNewCareCosts,
      actualNewCareCosts: data?.estatePlanning?.actualNewCareCosts,
      nextReviewDate: data?.estatePlanning?.nextReviewDate,
      isCareExpenditureAccountedInMainExpenditures: data?.estatePlanning?.isCareExpenditureAccountedInMainExpenditures,
      ownershipType: data?.estatePlanning?.ownershipType,
      considerPropertyInCareCosts: data?.estatePlanning?.considerPropertyInCareCosts,
      netSaleProceedsIfSellingForCare: data?.estatePlanning?.netSaleProceedsIfSellingForCare,
      hasNetSaleProceedsBeenIncludedInDepositsHeld: data?.estatePlanning?.hasNetSaleProceedsBeenIncludedInDepositsHeld,
      timeToReleaseSaleProceeds: data?.estatePlanning?.timeToReleaseSaleProceeds,
      howWillBeSaleProceedsUsed: data?.estatePlanning?.howWillBeSaleProceedsUsed,
      guaranteedIncomeForLife: data?.estatePlanning?.guaranteedIncomeForLife,
      notTakeInvestmentRiskInFundingForTheCareFeesCosts: data?.estatePlanning?.notTakeInvestmentRiskInFundingForTheCareFeesCosts,
      incomeIsMoreImportantThanCapitalGrowth: data?.estatePlanning?.incomeIsMoreImportantThanCapitalGrowth,
      leavingInheritanceToBeneficiaries: data?.estatePlanning?.leavingInheritanceToBeneficiaries,
      protectingCareCostsIncrease: data?.estatePlanning?.protectingCareCostsIncrease,
      abilityToIncreaseIncome: data?.estatePlanning?.abilityToIncreaseIncome,
      protectingCapitalFromInflation: data?.estatePlanning?.protectingCapitalFromInflation,
      careBillCertainty: data?.estatePlanning?.careBillCertainty,
      otherCustomerOrPOAPriorities: data?.estatePlanning?.otherCustomerOrPOAPriorities,
      amountOfBenefitToBePaidPerMonth: data?.estatePlanning?.amountOfBenefitToBePaidPerMonth,
      benefitsLumpSum: data?.estatePlanning?.benefitsLumpSum,
      escalation: data?.estatePlanning?.escalation,
      escalationPercentage: data?.estatePlanning?.escalationPercentage,
      additionalDeathBenefits: data?.estatePlanning?.additionalDeathBenefits,
      capitalProtectionPercentage: data?.estatePlanning?.capitalProtectionPercentage,
      deferredPeriod: data?.estatePlanning?.deferredPeriod,
      partlyMetCareShortfallImpact: data?.estatePlanning?.partlyMetCareShortfallImpact,
      futureCareSentiment: data?.estatePlanning?.futureCareSentiment,
      isNeedForCareLikelyToIncrease: data?.estatePlanning?.isNeedForCareLikelyToIncrease,
      makeSomeProvisionToEnsureForCare: data?.estatePlanning?.makeSomeProvisionToEnsureForCare,
    },
    customerSecond: {
      isCustomerTakingSpecialistAdvice: data?.customerSecondEstatePlanning?.isCustomerTakingSpecialistAdvice,
      skipWillQuestions: data?.customerSecondEstatePlanning?.skipWillQuestions || false,
      hasWill: data?.customerSecondEstatePlanning?.hasWill,
      willNeedsUpdate: data?.customerSecondEstatePlanning?.willNeedsUpdate,
      willLastUpdatedDate: data?.customerSecondEstatePlanning?.willLastUpdatedDate,
      wouldLikeFamilyWillToBeOneOfObjectives: data?.customerSecondEstatePlanning?.wouldLikeFamilyWillToBeOneOfObjectives,
      amountOfWill: data?.customerSecondEstatePlanning?.amountOfWill,
      addAmountOfWillToLoan: data?.customerSecondEstatePlanning?.addAmountOfWillToLoan,
      howMuchAmountOfWillAddToLoan: data?.customerSecondEstatePlanning?.howMuchAmountOfWillAddToLoan,
      conversationDetailsAboutWill: data?.customerSecondEstatePlanning?.conversationDetailsAboutWill,
      skipPOAQuestions: data?.customerSecondEstatePlanning?.skipPOAQuestions || false,
      substituteDecisionMaker: data?.customerSecondEstatePlanning?.substituteDecisionMaker,
      powerOfAttorneyType: data?.customerSecondEstatePlanning?.powerOfAttorneyType,
      detailsAboutAttorney: data?.customerSecondEstatePlanning?.detailsAboutAttorney,
      hasCopyOfPOA: data?.customerSecondEstatePlanning?.hasCopyOfPOA,
      wouldLikePOAToBeOneOfObjectives: data?.customerSecondEstatePlanning?.wouldLikePOAToBeOneOfObjectives,
      amountForPOA: data?.customerSecondEstatePlanning?.amountForPOA,
      addAmountForPOAToLoan: data?.customerSecondEstatePlanning?.addAmountForPOAToLoan,
      howMuchAmountForPOAAddToLoan: data?.customerSecondEstatePlanning?.howMuchAmountForPOAAddToLoan,
      customerOpinionRegardingPOA: data?.customerSecondEstatePlanning?.customerOpinionRegardingPOA,
      skipFuneralQuestions: data?.customerSecondEstatePlanning?.skipFuneralQuestions || false,
      wouldLikeToCoverFuneralCost: data?.customerSecondEstatePlanning?.wouldLikeToCoverFuneralCost,
      hasPlanedOwnFuneral: data?.customerSecondEstatePlanning?.hasPlanedOwnFuneral,
      existingFuneralProvisionAvailable: data?.customerSecondEstatePlanning?.existingFuneralProvisionAvailable,
      projectedShortfall: data?.customerSecondEstatePlanning?.projectedShortfall,
      areBeneficiariesAbleToPayFuneralCostBeforeHouseSale: data?.customerSecondEstatePlanning?.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale,
      wouldLikeToMakeFuneralProvisionOneOfObjectives: data?.customerSecondEstatePlanning?.wouldLikeToMakeFuneralProvisionOneOfObjectives,
      howToPayPrePaidFuneralPlan: data?.customerSecondEstatePlanning?.howToPayPrePaidFuneralPlan,
      amountToBeBorrowedForFuneralPlan: data?.customerSecondEstatePlanning?.amountToBeBorrowedForFuneralPlan,
      funeralPlansDiscussTime: data?.customerSecondEstatePlanning?.funeralPlansDiscussTime,
      otherNotesRegardingFuneralArrangements: data?.customerSecondEstatePlanning?.otherNotesRegardingFuneralArrangements,
      inNeedOrAlreadyInCare: data?.customerSecondEstatePlanning?.inNeedOrAlreadyInCare,
      hasUndergoneNHSCareAssessment: data?.customerSecondEstatePlanning?.hasUndergoneNHSCareAssessment,
      isIndividualAlreadyInCareHome: data?.customerSecondEstatePlanning?.isIndividualAlreadyInCareHome,
      careHomeContactName: data?.customerSecondEstatePlanning?.careHomeContactName,
      careHomeContactNamePosition: data?.customerSecondEstatePlanning?.careHomeContactNamePosition,
      careHomeContactNumber: data?.customerSecondEstatePlanning?.careHomeContactNumber,
      careHomeAddress: data?.customerSecondEstatePlanning?.careHomeAddress,
      typeOfExistingCare: data?.customerSecondEstatePlanning?.typeOfExistingCare,
      existingCareHours: data?.customerSecondEstatePlanning?.existingCareHours,
      existingCareCost: data?.customerSecondEstatePlanning?.existingCareCost,
      requiredCareType: data?.customerSecondEstatePlanning?.requiredCareType,
      howLongDoesIndividualWishToRemainInOwnHome: data?.customerSecondEstatePlanning?.howLongDoesIndividualWishToRemainInOwnHome,
      howLikelyIndividualRemainInOwnHome: data?.customerSecondEstatePlanning?.howLikelyIndividualRemainInOwnHome,
      howLikelyIndividualMoveBackAndForthHomeToCare: data?.customerSecondEstatePlanning?.howLikelyIndividualMoveBackAndForthHomeToCare,
      timescales: data?.customerSecondEstatePlanning?.timescales,
      hasFullFinancialAssessmentUndertakenYet: data?.customerSecondEstatePlanning?.hasFullFinancialAssessmentUndertakenYet,
      estimatedNewCareCosts: data?.customerSecondEstatePlanning?.estimatedNewCareCosts,
      actualNewCareCosts: data?.customerSecondEstatePlanning?.actualNewCareCosts,
      nextReviewDate: data?.customerSecondEstatePlanning?.nextReviewDate,
      isCareExpenditureAccountedInMainExpenditures: data?.customerSecondEstatePlanning?.isCareExpenditureAccountedInMainExpenditures,
      ownershipType: data?.customerSecondEstatePlanning?.ownershipType,
      considerPropertyInCareCosts: data?.customerSecondEstatePlanning?.considerPropertyInCareCosts,
      netSaleProceedsIfSellingForCare: data?.customerSecondEstatePlanning?.netSaleProceedsIfSellingForCare,
      hasNetSaleProceedsBeenIncludedInDepositsHeld: data?.customerSecondEstatePlanning?.hasNetSaleProceedsBeenIncludedInDepositsHeld,
      timeToReleaseSaleProceeds: data?.customerSecondEstatePlanning?.timeToReleaseSaleProceeds,
      howWillBeSaleProceedsUsed: data?.customerSecondEstatePlanning?.howWillBeSaleProceedsUsed,
      guaranteedIncomeForLife: data?.customerSecondEstatePlanning?.guaranteedIncomeForLife,
      notTakeInvestmentRiskInFundingForTheCareFeesCosts: data?.customerSecondEstatePlanning?.notTakeInvestmentRiskInFundingForTheCareFeesCosts,
      incomeIsMoreImportantThanCapitalGrowth: data?.customerSecondEstatePlanning?.incomeIsMoreImportantThanCapitalGrowth,
      leavingInheritanceToBeneficiaries: data?.customerSecondEstatePlanning?.leavingInheritanceToBeneficiaries,
      protectingCareCostsIncrease: data?.customerSecondEstatePlanning?.protectingCareCostsIncrease,
      abilityToIncreaseIncome: data?.customerSecondEstatePlanning?.abilityToIncreaseIncome,
      protectingCapitalFromInflation: data?.customerSecondEstatePlanning?.protectingCapitalFromInflation,
      careBillCertainty: data?.customerSecondEstatePlanning?.careBillCertainty,
      otherCustomerOrPOAPriorities: data?.customerSecondEstatePlanning?.otherCustomerOrPOAPriorities,
      amountOfBenefitToBePaidPerMonth: data?.customerSecondEstatePlanning?.amountOfBenefitToBePaidPerMonth,
      benefitsLumpSum: data?.customerSecondEstatePlanning?.benefitsLumpSum,
      escalation: data?.customerSecondEstatePlanning?.escalation,
      escalationPercentage: data?.customerSecondEstatePlanning?.escalationPercentage,
      additionalDeathBenefits: data?.customerSecondEstatePlanning?.additionalDeathBenefits,
      capitalProtectionPercentage: data?.customerSecondEstatePlanning?.capitalProtectionPercentage,
      deferredPeriod: data?.customerSecondEstatePlanning?.deferredPeriod,
      partlyMetCareShortfallImpact: data?.customerSecondEstatePlanning?.partlyMetCareShortfallImpact,
      futureCareSentiment: data?.customerSecondEstatePlanning?.futureCareSentiment,
      isNeedForCareLikelyToIncrease: data?.customerSecondEstatePlanning?.isNeedForCareLikelyToIncrease,
      makeSomeProvisionToEnsureForCare: data?.customerSecondEstatePlanning?.makeSomeProvisionToEnsureForCare,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)
  const [skipRemainingCareQuestions, setSkipRemainingCareQuestions] = useState(localStorage.getItem('skipRemainingCareQuestions') === 'true' ? true : false)

  const { data: customers } = useGetCustomers(idNumber)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    delete newData?.customerFirst?._id
    delete newData?.customerSecond?._id

    setFormData({
      customerFirst: {
        ...newData.estatePlanning,
      },
      customerSecond: {
        ...newData.customerSecondEstatePlanning,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), name, value))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleCustomSwitch = ({ checked, field }: { checked: boolean; field: string }) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), field, checked))
  }

  const skipRemainingCareQuestionsChange = (checked: boolean) => {
    setSkipRemainingCareQuestions(checked)
    localStorage.setItem('skipRemainingCareQuestions', String(checked))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditEstatePlanning({
      idNumber,
      values: {
        ...formData,
        customerFirst: {
          ...formData.customerFirst,
          careHomeContactNumber: String(formData.customerFirst.careHomeContactNumber),
        },
        customerSecond: {
          ...formData.customerSecond,
          careHomeContactNumber: String(formData.customerSecond.careHomeContactNumber),
        },
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
    console.log('Failed:', errorInfo)
  }

  const disclosedBeneficiariesDataSource =
    beneficiaries.map((beneficiary: any) => ({
      id: beneficiary._id,
      name: beneficiary.name,
      age: beneficiary.age,
      relationship: beneficiary.relationship,
    })) || []

  const substituteDecisionMakerOptions = disclosedBeneficiariesDataSource
    .map((option: any) => ({
      label: option.name,
      value: option.id,
    }))
    .concat(initialSubstituteDecisionMakerOptions)

  const substituteDecisionMakerOptionsForCustomerFirst = [
    {
      label: customers?.customerSecond?.firstName,
      value: customers?.customerSecond?._id,
    },
    ...substituteDecisionMakerOptions,
  ]

  const substituteDecisionMakerOptionsForCustomerSecond = [
    {
      label: customers?.customerFirst?.firstName,
      value: customers?.customerFirst?._id,
    },
    ...substituteDecisionMakerOptions,
  ]

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleCustomSwitch,
    skipRemainingCareQuestions,
    skipRemainingCareQuestionsChange,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    disclosedBeneficiariesDataSource,
    customers,
    substituteDecisionMakerOptionsForCustomerFirst,
    substituteDecisionMakerOptionsForCustomerSecond,
  }
}

export default useEstatePlanning
