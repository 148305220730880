import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { commonUtils } from 'utils'
import Forms from 'contexts/Forms'
import { IClientDetails, IOtherConditionsDetails, IQuickSearch } from 'interfaces/quickSearch.interface'

const useOtherConditionDetails = () => {
  const [
    loading,
    // setLoading
  ] = useState(false)

  const clientDetails: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails
  const customerFirstName = clientDetails?.firstLife?.firstName
  const customerSecondName = clientDetails?.secondLife?.firstName
  const enterMedicalDetailsOfCustomerFirst = clientDetails?.firstLife?.enterMedicalDetails
  const enterMedicalDetailsOfCustomerSecond = clientDetails?.secondLife?.enterMedicalDetails && clientDetails?.isSecondLife

  const localData: IOtherConditionsDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').medicalDetails?.otherConditionsDetails

  const [formData, setFormData] = useState({
    customerFirst: {
      hasControlledDiabetes: localData?.customerFirst?.hasControlledDiabetes,
      diabetesDiagnosisDate: localData?.customerFirst?.diabetesDiagnosisDate,
      hasParkinsonDisease: localData?.customerFirst?.hasParkinsonDisease,
      parkinsonRequiresDailyMedication: localData?.customerFirst?.parkinsonRequiresDailyMedication,
      parkinsonRequiresMobilityAIDS: localData?.customerFirst?.parkinsonRequiresMobilityAIDS,
      parkinsonDiagnosisDate: localData?.customerFirst?.parkinsonDiagnosisDate,
      hasMultipleSclerosis: localData?.customerFirst?.hasMultipleSclerosis,
      sclerosisRequiresMobilityAIDS: localData?.customerFirst?.sclerosisRequiresMobilityAIDS,
      sclerosisDiagnosisDate: localData?.customerFirst?.sclerosisDiagnosisDate,
      isDiagnosedWithChronicRespiratoryDisease: localData?.customerFirst?.isDiagnosedWithChronicRespiratoryDisease,
      respiratoryDiseaseDiagnosisDate: localData?.customerFirst?.respiratoryDiseaseDiagnosisDate,
      hasDementia: localData?.customerFirst?.hasDementia,
      dementiaDiagnosisDate: localData?.customerFirst?.dementiaDiagnosisDate,
      hasChronicKidneyFailure: localData?.customerFirst?.hasChronicKidneyFailure,
      kidneyFailureDiagnosisDate: localData?.customerFirst?.kidneyFailureDiagnosisDate,
      hadOrganTransplant: localData?.customerFirst?.hadOrganTransplant,
      organTransplantSurgeryDate: localData?.customerFirst?.organTransplantSurgeryDate,
      hasCirrhosisOfLiver: localData?.customerFirst?.hasCirrhosisOfLiver,
      liverCirrhosisDiagnosisDate: localData?.customerFirst?.liverCirrhosisDiagnosisDate,
      isDiagnosedWithMotorNeuronDiseases: localData?.customerFirst?.isDiagnosedWithMotorNeuronDiseases,
      motorNeuronDiseasesDiagnosisDate: localData?.customerFirst?.motorNeuronDiseasesDiagnosisDate,
      hasHepatitisC: localData?.customerFirst?.hasHepatitisC,
      hepatitisCDiagnosisDate: localData?.customerFirst?.hepatitisCDiagnosisDate,
      isDiagnosedWithHIV: localData?.customerFirst?.isDiagnosedWithHIV,
      hivDiagnosisDate: localData?.customerFirst?.hivDiagnosisDate,
      hasTakenEarlyRetirementForIllHealth: localData?.customerFirst?.hasTakenEarlyRetirementForIllHealth,
      hasTakenEarlyRetirementForIllHealthForDifferentReason: localData?.customerFirst?.hasTakenEarlyRetirementForIllHealthForDifferentReason,
    },
    customerSecond: {
      hasControlledDiabetes: localData?.customerSecond?.hasControlledDiabetes,
      diabetesDiagnosisDate: localData?.customerSecond?.diabetesDiagnosisDate,
      hasParkinsonDisease: localData?.customerSecond?.hasParkinsonDisease,
      parkinsonRequiresDailyMedication: localData?.customerSecond?.parkinsonRequiresDailyMedication,
      parkinsonRequiresMobilityAIDS: localData?.customerSecond?.parkinsonRequiresMobilityAIDS,
      parkinsonDiagnosisDate: localData?.customerSecond?.parkinsonDiagnosisDate,
      hasMultipleSclerosis: localData?.customerSecond?.hasMultipleSclerosis,
      sclerosisRequiresMobilityAIDS: localData?.customerSecond?.sclerosisRequiresMobilityAIDS,
      sclerosisDiagnosisDate: localData?.customerSecond?.sclerosisDiagnosisDate,
      isDiagnosedWithChronicRespiratoryDisease: localData?.customerSecond?.isDiagnosedWithChronicRespiratoryDisease,
      respiratoryDiseaseDiagnosisDate: localData?.customerSecond?.respiratoryDiseaseDiagnosisDate,
      hasDementia: localData?.customerSecond?.hasDementia,
      dementiaDiagnosisDate: localData?.customerSecond?.dementiaDiagnosisDate,
      hasChronicKidneyFailure: localData?.customerSecond?.hasChronicKidneyFailure,
      kidneyFailureDiagnosisDate: localData?.customerSecond?.kidneyFailureDiagnosisDate,
      hadOrganTransplant: localData?.customerSecond?.hadOrganTransplant,
      organTransplantSurgeryDate: localData?.customerSecond?.organTransplantSurgeryDate,
      hasCirrhosisOfLiver: localData?.customerSecond?.hasCirrhosisOfLiver,
      liverCirrhosisDiagnosisDate: localData?.customerSecond?.liverCirrhosisDiagnosisDate,
      isDiagnosedWithMotorNeuronDiseases: localData?.customerSecond?.isDiagnosedWithMotorNeuronDiseases,
      motorNeuronDiseasesDiagnosisDate: localData?.customerSecond?.motorNeuronDiseasesDiagnosisDate,
      hasHepatitisC: localData?.customerSecond?.hasHepatitisC,
      hepatitisCDiagnosisDate: localData?.customerSecond?.hepatitisCDiagnosisDate,
      isDiagnosedWithHIV: localData?.customerSecond?.isDiagnosedWithHIV,
      hivDiagnosisDate: localData?.customerSecond?.hivDiagnosisDate,
      hasTakenEarlyRetirementForIllHealth: localData?.customerSecond?.hasTakenEarlyRetirementForIllHealth,
      hasTakenEarlyRetirementForIllHealthForDifferentReason: localData?.customerSecond?.hasTakenEarlyRetirementForIllHealthForDifferentReason,
    },
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const {
    quickSearch: { tabs, activeTab, setActiveTab },
  } = useContext(Forms)

  const navigateNext = () => {
    const { clientDetails, medicalQuestions, propertyAndLanding, furtherInformation } = tabs
    const tabValues = [clientDetails, propertyAndLanding, furtherInformation]
    if (medicalQuestions) tabValues.splice(1, 0, medicalQuestions)
    const activeTabIndex = tabValues.indexOf(activeTab)
    setActiveTab(tabValues[activeTabIndex + 1])
  }

  const submitData = () => {
    const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
    const newState = {
      ...localStorageData,
      medicalDetails: {
        ...localStorageData.medicalDetails,
        otherConditionsDetails: formData,
      },
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
  }

  const handleNext = () => {
    submitData()
    navigateNext()
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  return {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  }
}

export default useOtherConditionDetails
