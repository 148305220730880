import Router from 'Router'

import AuthContext from 'contexts/AuthContext'
import GeneralTabItemsContext from 'contexts/Forms'
import LayoutContext from 'contexts/LayoutContext'
import { useGetUser } from 'services/auth/auth.service'
import { useState } from 'react'
import { initialBreadcrumbData, initialGeneralTabItemsData, initialMedicalQuestionsTabItemsData, initialQuickSearchTabs } from 'hooks/clients/staticData'
import Modals from 'modals/Modals'

function App() {
  const user = useGetUser()

  const [generalTabItems, setGeneralTabItems] = useState(initialGeneralTabItemsData)
  const [medicalQuestionsTabItems, setMedicalQuestionsTabItems] = useState(initialMedicalQuestionsTabItemsData)
  const [detailedFormName, setDetailedFormName] = useState('')
  const [antdActiveTabKey, setAntdActiveTabKey] = useState('1')
  const [activeTab, setActiveTab] = useState(initialQuickSearchTabs.clientDetails)
  const [tabs, setTabs] = useState(initialQuickSearchTabs)
  const [displayDetailForm, setDisplayDetailForm] = useState(false)
  const [customerToEdit, setCustomerToEdit] = useState<1 | 2>(1)
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbData)
  const [propertyToEdit, setPropertyToEdit] = useState('')
  const [quoteResultLoading, setQuoteResultLoading] = useState(false)
  const [quoteResultActiveTab, setQuoteResultActiveTab] = useState('LIFE TIME MORTGAGES')

  const values = {
    generalTabItems,
    setGeneralTabItems,
    medicalQuestionsTabItems,
    setMedicalQuestionsTabItems,
    detailedFormName,
    setDetailedFormName,
    antdActiveTabKey,
    setAntdActiveTabKey,
    displayDetailForm,
    setDisplayDetailForm,
    customerToEdit,
    setCustomerToEdit,
    breadcrumbItems,
    setBreadcrumbItems,
    propertyToEdit,
    setPropertyToEdit,
    quickSearch: {
      tabs,
      setTabs,
      activeTab,
      setActiveTab,
    },
    quoteResultLoading,
    setQuoteResultLoading,
    quoteResultActiveTab,
    setQuoteResultActiveTab,
  }

  const [sideBarWidth, setSideBarWidth] = useState(250)
  const [collapsed, setCollapsed] = useState(false)

  const layOutContextValues = {
    sideBarWidth,
    setSideBarWidth,
    collapsed,
    setCollapsed,
  }

  return (
    <AuthContext.Provider value={user}>
      <GeneralTabItemsContext.Provider value={values}>
        <LayoutContext.Provider value={layOutContextValues}>
          <Router />
          <Modals />
        </LayoutContext.Provider>
      </GeneralTabItemsContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
