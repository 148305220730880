import { Packer, Document, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, VerticalAlign, ShadingType, Footer, ImageRun, TabStopType } from 'docx'
import { fundsRequiredInOptions, purposeOfRaisingCapitalOptions } from 'modals/ObjectiveModal/ObjectiveModal.helper'
import {
  attitudeToRiskOptions,
  changesOptions,
  facilitatingLoanOptions,
  importanceOptions,
  repayOptions,
} from 'pages/ClientDetails/DetailedForm/Forms/BorrowingSolutionAndPriorities/BorrowingSolutionAndPriorities.helper'
import { mainPurposeOfDiscussionOptions } from 'pages/ClientDetails/DetailedForm/Forms/CaseOverview/CaseOverview.helper'
import { capabilityOptions, healthOptions, lifeEventsOptions, resilienceOptions } from 'pages/ClientDetails/DetailedForm/Forms/Health/Health.helper'
import { employmentStatusOptions } from 'pages/ClientDetails/DetailedForm/Forms/Income/Income.helper'
import { periodOptions } from 'utils/commonOptions'
import { ClientFormsInfo } from './SuitabilityLetterTabContent.types'
import { IRecommendedProduct } from 'services/shared/mutation.payload'
import { commonUtils } from 'utils'
import {
  TakingCashFromPensionsWhenTheyMatureOptions,
  askFamilyForAssistanceOptions,
  cuttingBackOnCurrentSpendingOptions,
  lessExpensivePropertyOptions,
  localAuthorityOptions,
  lodgerOptions,
  rentOrMoveWithFriendsOptions,
  returnToWorkOptions,
  savingsOptions,
} from 'pages/ClientDetails/DetailedForm/Forms/ObjectivesAndAlternatives/ObjectivesAndAlternatives.helper'

interface ICustomParagraphOptions {
  bold?: boolean
  size?: number
}

interface IOption {
  label: string
  value: string
}

interface IOptions {
  headerColor?: string
  headingLevel?: number
}

const generateCustomSection = (title: string, content: string[], { headerColor, headingLevel }: IOptions = {}) => {
  const paragraphs: any = []

  // Add title paragraph
  paragraphs.push(
    new Paragraph({
      children: [
        new TextRun({
          text: title,
          size: typeof headingLevel === 'number' ? headingLevel : 32,
          color: headerColor ? headerColor : '38033D',
          bold: true,
        }),
      ],
      spacing: {
        after: 120,
      },
      heading: HeadingLevel.HEADING_3,
    }),
  )

  // Add content paragraphs
  content.forEach((text: string, index: number) => {
    const isLast = index === content.length - 1
    paragraphs.push(
      new Paragraph({
        children: [
          new TextRun({
            text: text,
            size: 26,
            bold: false,
            color: '1A1919',
          }),
          isLast
            ? new TextRun({
                text: '',
                size: 26, // Adjust the size as needed
                break: 1,
              })
            : new TextRun({ text: '' }),
        ],
        spacing: {
          after: 120, // Use different spacing after the last paragraph if needed
        },
      }),
    )
  })

  return paragraphs
}

const generateBulletSection = (title: string, bulletPoints: string[], { headerColor, headingLevel }: IOptions = {}) => {
  const paragraphs = []

  // Title paragraph
  if (title) {
    paragraphs.push(
      new Paragraph({
        children: [
          new TextRun({
            text: title,
            size: typeof headingLevel === 'number' ? headingLevel : 32,
            color: !!headerColor ? headerColor : '38033D',
            bold: true,
          }),
        ],
        heading: HeadingLevel.HEADING_3,
        spacing: {
          after: 120,
        },
      }),
    )
  }

  // Bullet points
  bulletPoints.forEach((point, index) => {
    const isLast = index === bulletPoints.length - 1
    paragraphs.push(
      new Paragraph({
        children: [
          new TextRun({
            text: point,
            size: 26, // Assuming size 22 corresponds to 11pt
            color: '1A1919',
          }),
          isLast
            ? new TextRun({
                text: '',
                size: 26, // Adjust the size as needed
                break: 1,
              })
            : new TextRun({ text: '' }),
        ],
        bullet: {
          level: 0,
        },
        spacing: {
          before: 120,
          after: isLast ? 300 : 120, // Larger space after the last item
        },
      }),
    )
  })

  return paragraphs
}

const generateEmployMentStatusTableHeaderCell = (text: string) => {
  return new TableCell({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: text,
            color: 'FFFFFF', // Set text color to white
            bold: true,
            size: 27,
          }),
        ],
      }),
    ],
    shading: {
      fill: 'E562DD',
      type: ShadingType.CLEAR,
      color: 'auto',
    },
    verticalAlign: VerticalAlign.CENTER,
  })
}

const generateEmployMentStatusTableBodyCell = (text: string) => {
  return new TableCell({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: text,
            color: '1A1919',
            size: 26,
          }),
        ],
      }),
    ],
  })
}

const generateCustomParagraph = (text: string, options?: ICustomParagraphOptions) => {
  return new Paragraph({
    children: [
      new TextRun({
        text,
        size: options?.size || 26,
        bold: options?.bold || false,
        color: '1A1919',
      }),
      new TextRun({
        text: ' ',
        size: 4,
        break: 1,
      }),
    ],
  })
}

const generateCustomHeader3 = (text: string) => {
  return new Paragraph({
    children: [
      new TextRun({
        text,
        size: 32,
        color: '38033D',
        bold: true,
      }),
      new TextRun({
        text: ' ',
        size: 13,
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_3,
  })
}

const generatePinkCustomHeader3 = (text: string) => {
  return new Paragraph({
    children: [
      new TextRun({
        text,
        size: 28,
        color: 'E668DF',
        bold: true,
      }),
    ],
    spacing: {
      after: 120,
    },
    heading: HeadingLevel.HEADING_3,
  })
}

const generateCustomHeader2 = (text: string) => {
  return new Paragraph({
    children: [
      new TextRun({
        text,
        size: 36,
        color: '38033D',
        bold: true,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
    spacing: {
      after: 120,
    },
  })
}

const generateSecondTableRow = (title: string, value: string) => {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: title, // Text to display
                bold: true, // Make text bold
                color: '1A1919',
                size: 27,
              }),
            ],
          }),
        ],
        shading: {
          fill: 'FFFFFF', // Background color of the cell
          type: ShadingType.CLEAR,
          color: 'auto',
        },
        verticalAlign: VerticalAlign.CENTER,
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: value,
                color: '1A1919',
                size: 26,
              }),
            ],
          }),
        ],
        shading: {
          fill: 'FFFFFF', // Background color of the cell
          type: ShadingType.CLEAR,
          color: 'auto',
        },
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  })
}

const generateBulletWithResponse = (bulletText: string, response?: string) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `${bulletText}`,
        size: 26, // Font size, e.g., 26
        color: '1A1919',
      }),
      new TextRun({
        text: '',
        size: 26, // Adjust the size as needed
        break: 1,
      }),
      new TextRun({
        text: 'Response: ',
        size: 26, // Font size, e.g., 26
        color: '008000',
      }),
      new TextRun({
        text: `${response || 'N/A'}`,
        size: 26, // Font size, e.g., 26
        color: '1A1919',
      }),
    ],
    bullet: {
      level: 0, // Bullet list level, 0 for top-level
    },
    spacing: {
      before: 120,
    },
  })
}

// This function generates a space paragraph
const generateSpaceParagraph = () => {
  return new Paragraph({
    children: [
      new TextRun({
        text: ' ',
        size: 28, // Adjust the size as needed
        // break: 1,
      }),
    ],
  })
}

const buildDoc = async (data: ClientFormsInfo, disposableIncomeForNow: number, initialFundsTotalValue: number, recommendedProduct: IRecommendedProduct) => {
  const secondCustomerExists = !!data?.customerSecond?.firstName
  const hasEligibleBenefits = !!data.benefits.eligibleBenefits?.length
  const isReceivingAnyStateBenefits = data?.benefits?.benefitsReceiving?.length > 0
  const objectivesAndAlternatives = data?.objectivesAndAlternatives
  const objectives = objectivesAndAlternatives?.objectives?.filter((objective: any) => objective.needsToRaiseCapital)
  const borrowing = data.borrowingSolutionAndPriorities
  const weIOption = secondCustomerExists ? 'We' : 'I'
  const weAreIAmOption = secondCustomerExists ? 'We are' : 'I am'
  const displayRetireField = data?.income?.whenAreYouGoingToRetire || data?.customerSecondIncome?.whenAreYouGoingToRetire
  const liabilitiesCost = data?.liabilities?.otherLiabilities?.reduce((accumulator: number, currentValue: any) => {
    return accumulator + (currentValue.monthlyCost || 0)
  }, 0)
  const monthlyExpenditure = data.expenditure.expendituresDetails?.reduce((accumulator: number, currentValue: any) => {
    let valueToAdd = 0

    if (currentValue.expenditurePeriod === 'monthly') {
      valueToAdd = currentValue.expenditureAmount
    } else {
      // annually to monthly
      valueToAdd = currentValue.expenditureAmount / 12
    }

    return accumulator + valueToAdd
  }, 0)
  const totalMonthlyNetIncome = data.income.incomeSources?.reduce((accumulator: number, currentValue: any) => {
    return accumulator + (currentValue.monthlyNetIncome || 0)
  }, 0)
  const totalMonthlyExpenditure = monthlyExpenditure + liabilitiesCost
  const totalAssets = data.assets.otherAssets?.reduce((accumulator: number, currentValue: any) => {
    return accumulator + (currentValue.assetValue || 0)
  }, 0)

  async function convertLocalImageToBase64(imagePath: string): Promise<string> {
    const response = await fetch(imagePath)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve((reader.result as string).split(',')[1])
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const imageBase64: string = await convertLocalImageToBase64('https://i.imgur.com/5kj8oRj.png')

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          // Logo and tagline
          new Paragraph({
            children: [
              new ImageRun({
                data: imageBase64,
                transformation: {
                  width: 180,
                  height: 80,
                },
              }),
            ],
          }),
          // Spacer after logo and tagline
          new Paragraph({
            children: [new TextRun({ text: '', break: 1 })],
          }),
          // Title
          new Paragraph({
            children: [
              new TextRun({
                text: 'Suitability Report',
                bold: true,
                size: 48,
                color: '3d0332', // Color changed to match the table's first row content
              }),
            ],
          }),
          new Paragraph({
            // This empty paragraph acts as a spacer
            children: [
              new TextRun({
                text: ' ',
                size: 12, // Reduced size for less spacing
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: 'Report Date: ',
                bold: true,
                size: 28,
                color: '3d0332',
              }),
              new TextRun({
                text: `${new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date())}`,
                size: 28,
                color: '000000',
              }),
            ],
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: 'Name: ',
                bold: true,
                size: 28,
                color: '3d0332',
              }),
              new TextRun({
                text: `${data.customerFirst.firstName}`,
                size: 28,
                color: '000000',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '',
                size: 28, // Example: 24pt font (48 half-point)
                bold: true,
              }),
            ],
            heading: HeadingLevel.TITLE,
            spacing: {
              after: 200,
            },
          }),
          generateCustomParagraph(`Dear ${data.customerFirst.firstName}`),
          generateCustomParagraph(
            `I write further to our meeting on ${data.caseOverview.factFindStartDate}, during which we discussed your financial circumstances and your objectives, which I recorded in a 'fact-find' document. A copy of which is available upon request and the information provided is what has formed the basis of my advice and recommendations. This letter provides you with a comprehensive summary of my recommendations which are tailored to your specific needs and circumstances.`,
          ),
          generateCustomParagraph(
            'I have also enclosed a further copy of your lifetime mortgage illustration, which gives full details of the product that I have recommended. Please read through this and do not hesitate to contact me with any questions.',
          ),
          generateSpaceParagraph(),
          generateCustomHeader3('Scope of Advice'),
          generateCustomParagraph(
            `Your initial request was that I limit the scope of my advice purely to your need to ${
              mainPurposeOfDiscussionOptions.find((item) => item.value === data.caseOverview.mainPurposeOfDiscussion)?.label
            } As you have provided me with extensive information, I will draw attention to any alternatives to borrowing and any related financial matters you should be aware of. My advice will be limited to lending products only. You have not requested advice nor have I offered any in any other area related to financial planning.`,
          ),
          data.caseOverview.productResearchEnvironment === 'Whole Of Market'
            ? generateCustomParagraph(
                `I compare products from Whole Of Market and provide advice and a recommendation of a lender and a particular financial product best suited to your needs. I will always consider the options available within the mainstream mortgage market, the retirement interest only mortgage market and the lifetime mortgage market. My specific reasons for selecting the product type and the product provider are clearly stated within the recommendation section below.`,
              )
            : generateCustomParagraph(
                `I have provided you with the list of providers that I have access to and the product I am recommending has been selected from this panel.`,
              ),
          data.caseOverview.productResearchEnvironment === 'Panel'
            ? generateCustomParagraph(
                `I have provided an Initial Disclosure document separately which explains the service that I am providing you with and how I am remunerated for my work in collecting all necessary information, financial and product research, preparing my financial recommendations and submitting/managing your application with a product provider.`,
              )
            : {},
          data.caseOverview.productResearchEnvironment ? generateSpaceParagraph() : {},
          generateSpaceParagraph(),
          generateCustomHeader3('Family & Third Party Involvement'),
          !data.family.isReasonForNotInvolvingFamilyOrBeneficiaries
            ? generateCustomParagraph(
                `I have made you aware that you could have involvement from family or a third party. You told me you have discussed your plans with your family and/or beneficiaries and ${
                  data.caseOverview.wasAnyOtherPersonPresent
                    ? `you wanted ${data.caseOverview.personsPresentDuringFactFind
                        .map((person: any) => `${person.name}(${person.relationshipWithCustomer})`)
                        .join(', ')} Involved in our discussions`
                    : 'nobody else was involved'
                }.`,
              )
            : {},
          generateSpaceParagraph(),
          data.family.isReasonForNotInvolvingFamilyOrBeneficiaries
            ? generateCustomParagraph(
                `You said that you did not want family members and/or beneficiaries involved with your plans. The reasons have been recorded in the fact find document.`,
              )
            : {},
          data.family.isReasonForNotInvolvingFamilyOrBeneficiaries
            ? generateCustomParagraph(
                `I have offered to send a copy of my recommendations to any family members or people close to you however you told me this will not be necessary.`,
              )
            : {},
          generateSpaceParagraph(),

          ...generateCustomSection('Vulnerability', [
            'As a responsible adviser I take customer vulnerability seriously, regardless of age. We want to treat all customers fairly and ensure that you fully understand your options, as well as my recommendation. If there is anything you are unsure of it is vital that you contact me, before you proceed.',
            'During our discussions, and in all the information you provided in response to my questions, I did not identify that you were particularly vulnerable or that you were subject to any undue influence from a third party.',
            `${data.customerFirst.firstName} Health: ${data.health.healthIssues
              .map((healthIssue: string) => healthOptions.find((item: IOption) => item.value === healthIssue)?.label)
              .join(', ')} Life events: ${data.health.lifeEvents
              .map((lifeEvent: string) => lifeEventsOptions.find((item: IOption) => item.value === lifeEvent)?.label)
              .join(', ')} Resilience: ${data.health.resilience
              .map((resilienceItem: string) => resilienceOptions.find((item: IOption) => item.value === resilienceItem)?.label)
              .join(', ')} Capability: ${data.health.capability
              .map((capabilityItem: string) => capabilityOptions.find((item: IOption) => item.value === capabilityItem)?.label)
              .join(', ')}`,
          ]),
          ...generateCustomSection('Current Circumstances', [
            'In this section I have provided an outline of your current circumstances that in addition to the more detailed information in the fact find, have formed the basis of my advice and recommendations.',
          ]),
          generateCustomHeader3(`Employment Status: ${employmentStatusOptions.find((item: IOption) => item.value === data.income.employmentStatus)?.label}`),
          generateSpaceParagraph(),
          new Table({
            columnWidths: [3333, 3333, 3334],
            rows: [
              // Header row with styles
              new TableRow({
                children: [
                  generateEmployMentStatusTableHeaderCell('Name'),
                  generateEmployMentStatusTableHeaderCell('Employment Status'),
                  ...(displayRetireField ? [generateEmployMentStatusTableHeaderCell('Planned Retirement')] : []),
                ],
              }),
              // Data rows
              new TableRow({
                children: [
                  generateEmployMentStatusTableBodyCell(`${data.customerFirst.firstName}`),
                  generateEmployMentStatusTableBodyCell(
                    `${employmentStatusOptions.find((item: IOption) => item.value === data.income.employmentStatus)?.label}`,
                  ),
                  ...(displayRetireField ? [generateEmployMentStatusTableBodyCell(data?.income?.whenAreYouGoingToRetire || 'N/A')] : []),
                ],
              }),
              ...(secondCustomerExists
                ? [
                    new TableRow({
                      children: [
                        generateEmployMentStatusTableBodyCell(`${data.customerSecond.firstName}`),
                        generateEmployMentStatusTableBodyCell(
                          `${employmentStatusOptions.find((item: IOption) => item.value === data.customerSecondIncome.employmentStatus)?.label}`,
                        ),
                        ...(displayRetireField ? [generateEmployMentStatusTableBodyCell(data?.customerSecondIncome?.whenAreYouGoingToRetire || 'N/A')] : []),
                      ],
                    }),
                  ]
                : []),
            ],
          }),
          generateSpaceParagraph(),
          ...generateBulletSection('Your Income', [`Your total monthly net income is currently £${commonUtils.formatNumberWithCommas(totalMonthlyNetIncome)}`]),
          ...generateBulletSection('Your Expenditure', [
            `Your total monthly expenditure is currently £${commonUtils.formatNumberWithCommas(totalMonthlyExpenditure)}`,
          ]),
          ...generateBulletSection('Disposable Income Forecast', [
            `Your disposable income is currently £${commonUtils.formatNumberWithCommas(disposableIncomeForNow)}`,
          ]),
          generateSpaceParagraph(),
          ...generateCustomSection('Your Assets', [
            `You told me that apart from your main home, you have other assets that total an estimated £${commonUtils.formatNumberWithCommas(
              totalAssets,
            )} as detailed in the Assets section of the fact find.`,
            'You told me that you do not want to use any of the above assets towards raising the sum of money. At our initial meeting, we discussed having an emergency cash fund available with immediate access to cover any unforeseen expenditure that may arise.',
            `You told me that you keep £${commonUtils.formatNumberWithCommas(
              data.assets.howMuchCashHasInDeposit || 0,
            )} cash on deposit for emergencies. We agreed that this amount should cover any unforeseen emergencies. You confirmed that you are happy with your required emergency cash fund and wish to proceed with this amount.`,
          ]),
          ...generateCustomSection('Liabilities', [
            'You told me you do not have any liabilities such as loans or credit cards.',
            `You advised me you have total liabilities £${commonUtils.formatNumberWithCommas(liabilitiesCost)}`,
          ]),
          generateCustomHeader3('Benefits'),
          generateCustomParagraph(
            'Releasing equity from your home may have an adverse effect on means tested benefits.  It is therefore essential that we identify any benefits that you might be entitled to claim to ensure this can be managed.  Carrying out a benefits check could also identify whether you are entitled to benefits that you might be entitled to and not currently claiming.  Means tested benefits mat be available to any clients who have an annual income under £15,000 ( single) or £22,000 ( joint).',
          ),
          !isReceivingAnyStateBenefits ? generateCustomParagraph('You have advised me that you do not currently receive any benefits.') : {},
          isReceivingAnyStateBenefits ? generateCustomParagraph('You advised me that you are currently in receipt of the following benefits:-') : {},
          isReceivingAnyStateBenefits ? generateSpaceParagraph() : {},
          isReceivingAnyStateBenefits
            ? new Table({
                columnWidths: [5000, 5000],
                rows: [
                  // Header row with styles
                  new TableRow({
                    children: [generateEmployMentStatusTableHeaderCell('Benefit Period'), generateEmployMentStatusTableHeaderCell('Benefit Amount')],
                  }),
                  // Data rows
                  ...data.benefits.benefitsReceiving.map((benefit: any) => {
                    return new TableRow({
                      children: [
                        generateEmployMentStatusTableBodyCell(periodOptions.find((item: IOption) => item.value === benefit.benefitPeriod)?.label || ''),
                        generateEmployMentStatusTableBodyCell(`£${commonUtils.formatNumberWithCommas(benefit.benefitAmount || 0)}`),
                      ],
                    })
                  }),
                ],
              })
            : {},
          generateSpaceParagraph(),

          hasEligibleBenefits
            ? generateCustomParagraph('We conducted a benefits check and this indicated that you might be eligible to claim the following benefits:')
            : generateCustomParagraph('We conducted a benefits check and this indicated that you are not eligible for any further benefits.'),
          hasEligibleBenefits
            ? new Table({
                columnWidths: [5000, 5000],
                rows: [
                  // Header row with styles
                  new TableRow({
                    children: [generateEmployMentStatusTableHeaderCell('Benefit Period'), generateEmployMentStatusTableHeaderCell('Benefit Amount')],
                  }),
                  // Data rows
                  ...data.benefits.eligibleBenefits.map((benefit: any) => {
                    return new TableRow({
                      children: [
                        generateEmployMentStatusTableBodyCell(periodOptions.find((item: IOption) => item.value === benefit.benefitPeriod)?.label || ''),
                        generateEmployMentStatusTableBodyCell(`£${commonUtils.formatNumberWithCommas(benefit.benefitAmount || 0)}`),
                      ],
                    })
                  }),
                ],
              })
            : {},
          generateSpaceParagraph(),
          generateCustomHeader3('Your Health'),
          generateCustomParagraph(
            `Regarding your health and lifestyle, you have disclosed to me your health and lifestyle issues and these are documented in the Health section of the fact find. During our discussion I asked you about any health issues because certain lifetime mortgages offer better terms for customers with certain health issues. I recorded your responses on this issue in the fact find health section and have taken this into account in my recommendation. For more information about how health conditions can offer better terms, see the Product Specifics & Reasons Why section below.}`,
          ),
          data.health.hasAlterationToTypicalLifeExpectancy ? generateSpaceParagraph() : {},
          data.health.hasAlterationToTypicalLifeExpectancy
            ? generateCustomParagraph(
                `You advised me that you had health conditions which may qualify you for enhanced rates which I will take into account as part of my recommendation.`,
              )
            : {},
          generateSpaceParagraph(),
          generateCustomHeader2('Your Financial Situation'),
          generateSpaceParagraph(),
          generatePinkCustomHeader3('Credit History'),
          generateCustomParagraph(
            'You confirmed that you do not have anything recorded in your credit history that could seriously affect your ability to borrow money.',
          ),
          data.creditHistory.hasEverJudgementRegistered
            ? generateCustomParagraph(
                `You have advised me that that you have a judgement or a loan default against you, Details: ${
                  data.creditHistory.applicantAndAmountDetails ? data.creditHistory.applicantAndAmountDetails : 'N/A'
                }`,
              )
            : {},
          data.creditHistory.hasEverBeenBankrupt
            ? generateCustomParagraph(
                `You have advised me that you have been declared bankrupt or have an arrangement ( IVA) with your creditors and ${
                  data.creditHistory.isBankruptcyFullyDischarged ? 'it has' : 'has not'
                } been discharged`,
              )
            : {},
          data.creditHistory.hasEverBeenLateForPayments
            ? generateCustomParagraph(
                `You have advised me that you have failed to keep up payments under previous or current mortgage, loan or rental agreement.`,
              )
            : {},
          generateSpaceParagraph(),
          ...generateBulletSection(
            'Cash Flow & Managing Debts',
            [
              `You told me that ${data.creditHistory.isStrugglingWithPayments ? 'you are' : 'are not'} currently having any issues with repaying debt.`,
              'You have confirmed that you are not having any difficulty keeping up with your household bills.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateBulletSection(
            'Affordability',
            [
              `${
                data.income.isHappyWithCurrentIncome
                  ? 'You have stated that you are happy with your current levels of income.'
                  : `You have stated that you are not happy with your current levels of income. You would like an monthly income increase of £${data.income.requiredAdditionalMonthlyIncome}`
              }`,
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          generateCustomParagraph('In order to plan ahead effectively, we should consider the effect on your household income should either of you die.'),
          generateCustomParagraph(
            `You told me that you anticipate that if ${data.customerFirst.firstName} passed away, your total net monthly income would be unaffected.`,
          ),
          secondCustomerExists
            ? generateCustomParagraph(
                `You told me that you anticipate that if ${data.customerSecond.firstName} passed away, your total net monthly income would be unaffected.`,
              )
            : {},
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Future Changes',
            [
              'You confirmed to me any anticipated future changes to your circumstances including income and expenditure during our fact-finding discussion and this has been taken into account in my recommendation. If there is anything that you reasonably expect to happen in the future that could have an effect on the advice I give, please let me know. This can include expected windfalls of money, including pensions that have not yet matured, or expected future costs that you anticipate in the future.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          generatePinkCustomHeader3('Objectives'),
          generateCustomParagraph('You told me you have the following objectives that require you to raise capital:'),
          new Table({
            columnWidths: [4000, 2000, 2000, 2000],
            rows: [
              // Header row with styles
              new TableRow({
                children: [
                  generateEmployMentStatusTableHeaderCell('Purpose'),
                  generateEmployMentStatusTableHeaderCell('Amount'),
                  generateEmployMentStatusTableHeaderCell('Timescale'),
                  generateEmployMentStatusTableHeaderCell('Summary'),
                ],
              }),
              ...objectives.map((objective: any) => {
                return new TableRow({
                  children: [
                    generateEmployMentStatusTableBodyCell(
                      purposeOfRaisingCapitalOptions.find((item: IOption) => item.value === objective.purposeOfRaisingCapital)?.label || '',
                    ),
                    generateEmployMentStatusTableBodyCell(`£${commonUtils.formatNumberWithCommas(objective.requiredAmount)}`),
                    generateEmployMentStatusTableBodyCell(
                      fundsRequiredInOptions.find((item: IOption) => item.value === objective.fundsRequiredIn)?.label || '',
                    ),
                    generateEmployMentStatusTableBodyCell(objective.description || 'N/A'),
                  ],
                })
              }),
            ],
          }),
          generateCustomParagraph('During our discussions, I advised you that you should not release funds to be invested to generate wealth or income.'),
          generateSpaceParagraph(),
          generateCustomHeader2('Exploring Your Options'),
          generateSpaceParagraph(),
          generateCustomHeader3('Alternatives'),
          generateCustomParagraph(
            'Before considering raising funds by borrowing, we discussed and explored the following alternatives below. I have summarised your responses to each of these alternative options.',
          ),
          generateBulletWithResponse(
            'Would you consider cutting back on current spending?',
            cuttingBackOnCurrentSpendingOptions.find((item: IOption) => item.value === objectivesAndAlternatives.cuttingBackCurrentSpending)?.label,
          ),
          generateBulletWithResponse(
            'Will applying for State Benefits or Grants provide a possible solution?',
            localAuthorityOptions.find((item: IOption) => item.value === objectivesAndAlternatives.claimStateBenefits)?.label,
          ),
          generateBulletWithResponse(
            'Would you consider taking in a lodger?',
            lodgerOptions.find((item: IOption) => item.value === objectivesAndAlternatives.takeLodger)?.label,
          ),
          generateBulletWithResponse(
            'Would you consider a return to work?',
            returnToWorkOptions.find((item: IOption) => item.value === objectivesAndAlternatives.returnToWork)?.label,
          ),
          generateBulletWithResponse(
            'Would you consider asking family or relatives for a loan or financial assistance?',
            askFamilyForAssistanceOptions.find((item: IOption) => item.value === objectivesAndAlternatives.askFamilyForAssistance)?.label,
          ),
          generateBulletWithResponse(
            'Would you consider making use of other funds or assets?',
            savingsOptions.find((item: IOption) => item.value === objectivesAndAlternatives.useOtherFundsOrAssets)?.label,
          ),
          generateBulletWithResponse('Have you considered a standard mortgage?', objectivesAndAlternatives.standardMortgageDetails),
          generateBulletWithResponse(
            'have you considered a retirement interest-only mortgage?',
            objectivesAndAlternatives.retirementInterestOnlyMortgageDetails,
          ),
          generateBulletWithResponse('have you considered a personal loan?', objectivesAndAlternatives.personalLoanDetails),
          generateBulletWithResponse(
            'Would you consider moving to a less expensive property & using the sale proceeds?',
            lessExpensivePropertyOptions.find((item: IOption) => item.value === objectivesAndAlternatives.moveToCheaperProperty)?.label,
          ),
          generateBulletWithResponse(
            'Have you considered a retirement interest only mortgage',
            objectivesAndAlternatives.retirementInterestOnlyMortgageDetails,
          ),
          generateBulletWithResponse(
            'Would you consider taking cash from your pensions when they mature?',
            TakingCashFromPensionsWhenTheyMatureOptions.find((item: IOption) => item.value === objectivesAndAlternatives.takeCashFromPensions)?.label,
          ),
          generateBulletWithResponse(
            'Would you consider selling your property and renting or moving in with Family and Friends?',
            rentOrMoveWithFriendsOptions.find((item: IOption) => item.value === objectivesAndAlternatives.sellPropertyAndMove)?.label,
          ),
          generateCustomParagraph(
            'Having considered selling your property and the other alternatives, you have asked me to recommend a solution that will meet your objectives, taking into account your priorities and preferences.',
          ),
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Types of Borrowing',
            [
              'In looking at the types of borrowing solutions available, there are several methods of releasing equity from your home:',
              'Lifetime mortgage - A mortgage secured against your home, however you retain full ownership. Many product features are available with lifetime mortgages as described below in the Product Features section. However typically, no contractual monthly repayments are required unless you request a product that allows an optional payment. Interest can be "rolled-up" over your lifetime and the loan plus accrued interest is repaid after your death or permanent exit of the property, for example into permanent long term care.',
              'Retirement Interest Only - A retirement interest-only mortgage is only available on your main residence and is very similar to a standard interest-only mortgage, with two key differences.',
              '1. The loan is usually only paid off when you die, move into long term care or sell the house.',
              '2. You only have to prove you can afford the monthly interest repayments.',
              'Other forms of borrowing are available to you and these will be considered in my research and recommendation based on your needs. These include, Later Life Mortgages with a fixed term. Traditional mortgages, secured loans and personal loans however I have taken into account your wishes when we explored these alternatives.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          generateCustomHeader2('Recommendation Summary'),
          generateSpaceParagraph(),
          generatePinkCustomHeader3('Borrowing Solutions and Priorities'),
          generateCustomParagraph('We discussed in some detail what your priorities and needs are in terms raising this capital. You confirmed the following:'),
          ...generateBulletSection('', [
            `This loan ${
              borrowing.isThisEnoughCapital ? 'does' : 'does not'
            } provide sufficient capital for your immediate and future needs.  Therefore we made the following adjustments to your objectives`,
            `It ${borrowing.isMaximumAdvanceNecessary ? 'was' : 'was not'} necessary to request the maximum initial advance.`,
            `You ${
              borrowing.wantToUtilizeAnyProductCashBack ? 'did' : 'did not'
            } require any cash back facility to go towards the total initial funds required.`,
            `It ${borrowing.isImportantToRetainHomeFullOwnership ? 'was' : 'was not'} important to you that you retained full ownership of your home.`,
            `Some of your objectives involved requiring capital in the future.  We discussed having a drawdown facility or cash reserve and ${
              borrowing.isCashReserveRequired ? 'did' : 'did not'
            } need this facility. Please note that a drawdown facility is not guaranteed and could be withdrawn at any time.  Any drawdown is subject to minimum limits and will be based on the prevailing interest rate at the time.  This may be more or less that your initial loan.  It is important that you contact me to discuss your options prior to taking a drawdown amount.`,
            `You  ${borrowing.isAdHocOrRegularPaymentLikable ? 'did' : 'did not'} want to make ad-hoc or regular payments towards the loan.`,
            `You preferred the option of having a lifetime mortgage, where the loan does not become repayable until after the last borrower has died or have left the property permanently for example into long term care.`,
            `You told me that you are ${repayOptions.find((item) => item.value === borrowing.howLikelyRepayFullLoanInLifetime)?.label}`,
            `It was ${
              importanceOptions.find((item) => item.value === borrowing.repayLoanFreeIfMoveHomeAfterFiveYrs)?.label
            } that you were able to repay the loan without charge if you moved home after 5 years for example if downsizing to a cheaper property.`,
            `It was ${
              importanceOptions.find((item) => item.value === borrowing.loanRepayImportantOnPartnerPassOrCare)?.label
            } to repay the loan in the event of either partner dying or going into long term care.`,
            `You ${
              borrowing.isAbleToPayFeeForValuation ? 'were' : 'were not'
            } willing to consider products that charge a valuation fee at application  if a cost saving is to be made in doing so.`,
            `With regard to a fee being charged for the loan by the lender on completion (when you receive your funds) you were happy to ${
              facilitatingLoanOptions.find((item) => item.value === borrowing.preferenceForLenderFeePayment)?.label
            }. I would emphasise as explained that where fees are being added to the loan, the cost will be higher than had you paid any from your own funds. This is because interest will accrue on the loan and the fees being added.`,
            `You ${borrowing.isFixedInterestRateRequired ? 'required' : 'did not require'} a fixed interest rate for the whole term of the loan.`,
            `You stated that you ${
              borrowing.areFundsRequiredQuickly ? 'did' : 'did not'
            } require the funds quickly.  I made you aware that applying to a lender who would complete on the loan more quickly may not secure you the most competitive interest rate.`,
            `With regard to any anticipated changes to your circumstances in the future. In our fact-find discussion I noted the following.\n You anticipate: ${
              changesOptions.find((item) => item.value === borrowing.anticipateFutureCircumstancesChanges)?.label
            }`,
            `As requested, I will only be considering borrowing products that are protected by the Equity Release Council product safeguards. More details about the protection this offers you can be found in the 'Product Specifics & Reasons Why' Section below.`,
            `You have requested that I consider products that are not protected by the Equity Release Council.  I have made you aware that you will not have the same level of protection for your loan as you would with a product that is protected by them.`,
          ]),
          generateSpaceParagraph(),
          ...generateCustomSection('Attitude to Risk', [
            `Your attitude to risk for your new loan is stated as ${attitudeToRiskOptions.find((item) => item.value === borrowing.attitudeToRisk)?.label}.`,
            'To put this into perspective, based upon the amount you borrow, future house price movements a the higher the loan, the more likely you are to exhaust any capital left in the property for your beneficiaries to  inherit.  A cautious person would therefore borrow less and a speculative borrower would borrow the highest amount available.',
          ]),
          generateCustomParagraph('Based on the above priorities and needs, the type of borrowing I am recommending is as follows:', { bold: true }),
          generateCustomParagraph('I am recommending a Lifetime Mortgage. A summary of the recommended product is below.'),
          new Table({
            columnWidths: [2500, 7500],
            rows: [
              generateSecondTableRow('Lender Name:', recommendedProduct.lenderName),
              generateSecondTableRow('Product Name:', recommendedProduct.productName),
              generateSecondTableRow('Property Value:', commonUtils.formatNumberWithCommas(recommendedProduct.propertyValue)),
              generateSecondTableRow('Initial Funds Released:', commonUtils.formatNumberWithCommas(recommendedProduct.initialFundsReleased)),
              generateSecondTableRow('Draw-Down Facility:', commonUtils.formatNumberWithCommas(recommendedProduct.drawDownFacility)),
              generateSecondTableRow('Interest Rate Type:', recommendedProduct.interestRateType),
              generateSecondTableRow('Monthly Interest Rate:', commonUtils.formatNumberWithCommas(recommendedProduct.monthlyInterestRate)),
              generateSecondTableRow('Annual Equivalent Rate:', commonUtils.formatNumberWithCommas(recommendedProduct.annualEquivalentRate)),
              generateSecondTableRow('Lifetime mortgage:', recommendedProduct.lifetimeMortgage),
              generateSecondTableRow('Valuation Fee (due at application):', commonUtils.formatNumberWithCommas(recommendedProduct.valuationFee)),
              generateSecondTableRow('Product Fee (due on completion of loan):', commonUtils.formatNumberWithCommas(recommendedProduct.productFee)),
              generateSecondTableRow('Advice Fee:', commonUtils.formatNumberWithCommas(recommendedProduct.adviceFee)),
              generateSecondTableRow('Estimated Solicitors Fees:', commonUtils.formatNumberWithCommas(recommendedProduct.estimatedSolicitorsFees)),
              generateSecondTableRow('Early Product Repayment Charges:', commonUtils.formatNumberWithCommas(recommendedProduct.earlyProductRepaymentCharges)),
            ],
          }),
          generateSpaceParagraph(),
          generateCustomParagraph(
            'Please note that my recommendations in this report are based on your current circumstances. If your circumstances should change (for instance, your expenditure were to unexpectedly increase) please contact me as my recommendations may no longer be suitable.',
          ),
          generateSpaceParagraph(),
          generateCustomHeader2('Product Specifics & Reasons Why'),
          generateSpaceParagraph(),
          ...generateBulletSection(
            'Capital Requirement',
            [`You wish to raise an initial capital sum of: £${commonUtils.formatNumberWithCommas(initialFundsTotalValue)}`],
            {
              headerColor: 'E668DF',
              headingLevel: 28,
            },
          ),
          generateCustomParagraph(
            'The interest rate for the any drawdown amounts will be set at the time that the withdrawal is requested and you will be required to confirm your acceptance of the rate before the cash will be released to you.',
          ),
          ...generateCustomSection('Benefits of Making Payments', [
            'Making payments, whether that is ad-hoc, optional interest payments or contractual mandatory payments will reduce the impact of the rolled-up interest on your loan and estate.',
          ]),
          ...generateCustomSection('Lifetime mortgage Interest Roll-Up / Voluntary Payment', [
            'What Are They? - An "Interest Roll-Up / Voluntary Payment" mortgage is where you do not have to make contractual repayments and instead, interest can be added to the loan over the term of the mortgage in a compound basis at the interest rate shown in the illustration. Its important to understand that making payments will reduce the impact of the rolled-up interest on your estate. The lender will only require repayment of the loan, plus interest accumulated, on the event that you have either gone into long term care or passed away. The loan may also need to be repaid if you were to move to a property that is unsuitable for the mortgage to be transferred to. You should consider carefully the cost of releasing funds from your property if you have no immediate need. Interest on your loan will be compounding at a products rate (MER).',
          ]),
          ...generateCustomSection('Voluntary Payments', [
            'What Are They? - This allows you to make payments voluntarily either as an ad-hoc one-off or occasional payment or quite regularly. These are optional and are typically limited to 10% to 12% of the loan each year. Please refer to your illustration for full details of how often you can make payments as well as the minimum and maximum amounts you can pay.',
            "I have recommended a Voluntary Payment lifetime mortgage. I have recommended this because of your Priorities and needs as described earlier. Remember that any payments are voluntary and you are not required to make payments if you don't wish to. It's important to understand that voluntary payments will reduce the impact of the rolled-up interest on your estate. However, if you do not make regular payments of interest, the interest will instead be added onto your mortgage balance and the total amount owing will increase, with each interest payment compounding on top of the previous one. This means that your estate will be reduced and the amount that your beneficiaries will receive will reduce.",
            'I have not recommended an Interest Only Mortgage. I have explained that these products can represent a cost saving compared to roll up interest mortgages however as detailed in the factfind and the "Priorities and Needs" section earlier, your preference was that you are not committed to making a contractual payment of interest each month.',
            'I have not recommended an Interest Serviced Lifetime Mortgage because as described in your Priorities and Needs section earlier, you did not want to service any of the interest on a monthly basis.',
            'I have not recommended a Capital & Interest Mortgage. I have explained that these products can represent a cost saving compared to all other types of mortgages however as detailed in the factfind and the "Priorities and Needs" section earlier, your preference was that you are not committed to making a contractual payment of interest each month.',
          ]),
          ...generateBulletSection('Interest', [
            'A fixed interest rate for life - as implied, this means the interest rate offered on the initial loan will remain fixed for the entire duration of the loan, or your lifetime.',
            'A fixed interest rate for period of years - the fixed interest rate will end and will usually revert to a variable rate of interest after a fixed period of time.',
            'A variable interest rate - this is where your interest rate can fluctuate and can go up or down. There are different types of variable interest rates, some are set by the lender, others are linked to an underlying variable rate, for example the Bank of England base interest rate.',
          ]),
          generateCustomParagraph('I have recommended the following type of interest rate for the following reasons.'),
          borrowing.isFixedInterestRateRequired
            ? generateCustomParagraph(
                'I have recommended a fixed interest rate for life. I have recommended this to provide you with certainty about the cost of the borrowing over the whole term and because based on my product research, this represented the best value product also.',
              )
            : {},
          generateCustomHeader3('Set Up Fees & Charges'),
          generateCustomParagraph(
            'NOTE: The Fees that apply in your case are fully detailed in the Recommendation Summary earlier in this Report. If the product I have recommended includes fees, please bear in mind that I have compared products with and without fees and have recommended the lowest cost product available to me using a "true cost" analysis over the likely term of the loan. Please ask if you require more information about how we compare the cost of products.',
          ),
          ...generateBulletSection('', [
            'Valuation Fee - this charge may be levied by the lender to pay for a surveyor to conduct a market valuation of your property. If this fee applies it will be payable when you apply for the product and submit your application.',
            'Product Administration Fee - sometimes called an Arrangement Fee, the lender applies a fee which is payable only when your loan completes and covers some of their costs in offering the loan at the given interest rate and setting up your loan.',
            'Advice Fee - This fee is charged by the financial adviser for conducting a thorough review of your circumstances and your objectives, providing advice and a recommendation and where applicable, arranging the product solution for you. Your adviser will explain the fee and when it will become payable and this is detailed in a separate document that will be given to you.',
            'Your Solicitors fee - If arranging a Lifetime Mortgage, a Home Reversion plan and some Retirement Interest Only mortgages, you will require a solicitor of your own choice to act on your behalf to provide legal oversight for you. They will confirm their fee before you proceed with an application and when this fee will be payable.',
            'Other fees may apply and these will be shown in the illustration and subsequent offer document that you will receive. Your solicitor will also point out the costs associated with taking out a product before you agree to proceed.',
          ]),
          generateCustomParagraph(
            "It's important to know that if you are adding fees to your loan, interest is payable on the fee amount for the duration of the loan.",
          ),
          generateCustomParagraph(
            'You wish to add the Lifetime Mortgage lender’s application/arrangement fee to the amount released as you wish to keep the initial costs you have to pay out of pocket to a minimum. I should make clear that the fee being added, including any other fees being added will accrue interest along with the rest of the loan making the cost of adding the fee higher due to the cost of the interest over the term.  You confirm that you understand this and you are happy to proceed on that basis.',
          ),
          generateCustomHeader3('Early Repayment Charges'),
          generateCustomParagraph(
            'Early Repayment Charges Explained. Equity Release products including lifetime mortgages are a long-lasting product solution and are designed not to be repaid until you die or go into long term residential care. However, should you wish to repay your loan early, an Early Repayment Charge may apply depending on the type of charge that applies and when you pay off the loan.',
          ),
          generateCustomParagraph('There are two main types of early repayment charges that apply to Equity Release products and Lifetime Mortgages.'),
          ...generateBulletSection('', [
            'Fixed Early Repayment Charge - based on a fixed percentage of the loan and this percentage can reduce gradually over a period of time. The charge usually expires after a period of years, for example 8, 10 or more years.',
            'Variable Early Repayment Charge - The amount of the charge can vary from day to day because it is linked to an underlying variable interest rate. There may be occasions where no charge applies because of underlying interest rate has increased substantially however you should expect that a charge will apply and that you will not know what this charge is until you request a redemption statement.',
          ]),
          generateCustomParagraph(
            `The product I have recommended ${
              borrowing.isDefinedEarlyRepaymentChargeRequired ? 'has' : 'does not have'
            } a Fixed Early Repayment Charge. Full details of the early repayment charge and how long it lasts for are contained in the illustration provided with this Report. I have deemed that this type of charge is suitable for you taking into account the information you gave me in our fact-find discussion as summarised earlier in the Priorities section.`,
          ),
          ...generateCustomSection('Overall Cost of Borrowing', [
            'Please refer to the illustration provided to you which indicates that if no interest payments are made over the term of 15 years, the amount outstanding on your lifetime mortgage would be << from illustration>>££££££££££££££',
          ]),
          ...generateCustomSection('Equity Release Council Safeguards', [
            'The product I have recommended meets all the Equity Release Council Product standards. For more information about the Equity Release Council visit https://www.equityreleasecouncil.com/For lifetime mortgages, interest rates must be fixed or, if they are variable, there must be a “cap” (upper limit) which is fixed for the life of the loan. You have the right to move to another property subject to the new property being acceptable to your product provider as continuing security for your equity release loan. The product must have a “no negative equity guarantee”. This means that when your property is sold, and agents’ and solicitors’ fees have been paid, even if the amount left is not enough to repay the outstanding loan to your provider, neither you nor your estate will be liable to pay any more. All customers taking out new plans which meet the Equity Release Council standards must have the right to make penalty free payments, subject to lending criteria.',
            'The above provides an outline of the various product features available and which features I have recommended as part of your borrowing solution. Please read the illustration that accompanies this suitability report in full to understand the specific details that apply to the product I have recommended.',
          ]),
          generateCustomHeader2('Important Considerations'),
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Property Ownership',
            [
              'Joint Ownership of Property - Your lifetime mortgage plan must reflect the names recorded on the property title deeds. If this is not the case, your solicitor may charge you for the work involved.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateCustomSection(
            'Others Living in Property',
            [
              'When the lender is required to sell the property, any individual living in the property will need to vacate it immediately. It will be a requirement of the Lifetime Mortgage that anyone who resides with you, signs an ‘Occupants Deed’. The ‘Occupants Deed’ is a disclaimer whereby the individual agrees to leave the property upon your death whenever that may be, or upon abandonment of the property as a result of you going into long term care.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateCustomSection(
            'Property Insurance',
            [
              'You understand and accept that suitable buildings insurance cover will need to be in place before your Lifetime Mortgage provider will release your loan to you. This will be confirmed to the lender by your solicitor. I would also advise that if you do not already have it, that you seriously consider taking out “contents insurance” cover at the same time.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateCustomSection(
            'Benefits & Grants',
            [
              `You have told me ${isReceivingAnyStateBenefits ? 'you are' : 'are not'} in receipt of any state benefits.`,
              'I have checked your entitlement for state benefits and will provide a separate summary report showing what state benefits you may be able to claim. I have also highlighted to you that any means tested state benefits that may be affected if you release cash from your home and your savings or income exceeds the savings/income limit for that benefit. It is important that you understand how means tested benefits can be reduced or lost altogether if your savings or income exceeds certain limits. If you are unsure, please ask me for further guidance.',
              'If you are borrowing money to make any adaptations to your home to help mobility, purchasing mobility aids or funding any purchase as a result of a disability or old age, there may be grants available that will cover these costs for you. In order to find out whether you are eligible for such a grant, I would advise you to either visit the Age UK website (www.ageuk.org.uk) or contact their advice line on 0800 678 1602.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateCustomSection(
            'Deprivation of Assets',
            [
              'Please note that in the event of you being taken into care at some time in the future utilizing your home to fund this type of expenditure (holidays & lump sum gifts) could be construed as deprivation of assets. Deliberate deprivation of assets is where you have intentionally decreased your overall assets in order to reduce the amount, you are charged towards your care and support. The subjects of Deprivation of Assets and Taxation are both very detailed, so it is wise to ensure that professional guidance is sought in both of these areas.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          generatePinkCustomHeader3('Rights of Repossession'),
          generateCustomParagraph('The lender will have the right to repossess your property in the following circumstances:'),
          ...generateBulletSection('', [
            'If someone else moves into the property with you and you do not have permission from the Lifetime Mortgage provider.',
            'If all of the individuals party to the Lifetime Mortgage contract have moved into Long Term Care without the lender receiving the outstanding loan and the property has not sold within a time period (as set out by the lender). For further clarification on this, please see the mortgage illustration I provided you with.',
            'If you breach any of their terms and conditions, as set out in the offer or your conditions booklet.',
            'If you fail to keep up any contractual repayments required as part of the mortgage contract.',
          ]),
          generatePinkCustomHeader3('Risk Warnings'),
          generateCustomParagraph('In addition to the below warnings, further clarification should be sought from your solicitor.'),
          ...generateBulletSection('', [
            'Please be aware property values and prices fluctuate according to market conditions and the value of your property may go down as well as up.',
            'In the event of a party to a mortgage remarrying at a later date, you would need to apply to the lender for approval to include the new spouse on the mortgage.',
            'There are a limited number of providers that offer Lifetime Mortgages so consumer choice is limited.',
            'The value of your residual estate will be substantially reduced. In some cases, there may not be a residual estate to transfer to your beneficiaries as an inheritance.',
            'You are required to keep your property maintained to a required standard.',
            'In the future if you wish to move into Long-term Care, then there could be less or no money to pay for private care by releasing equity or taking income from your property now. The level of facilities offered by the state may differ from those offered by privately funded care.',
            'You are responsible for insuring your property.',
            'In future you may not be able to raise further funds.',
            'You may find it difficult in future to move from your property or switch to another Lifetime Mortgage provider - having raised capital from your existing property.',
            'You may find it difficult to adapt your home to meet your future physical needs if you do not qualify for any grants and you do not have adequate personal funds. You will also need permission from the provider.',
            'I have made you aware that by keeping the money released in a deposit account, there is a risk that you may not receive as much interest as the rolled-up interest being applied to your Lifetime Mortgage. The result of this is an erosion of your capital.',
            'Products not covered by the Equity Release Council standards do not offer a guarantee for non-negative equity.',
            'Should you voluntarily go into residential care or sheltered accommodation (where there is no clinical need), the lender will reserve the right to apply early repayment penalties.',
            'If your property is valued at a lower amount than you had originally anticipated I will contact you to ensure that the advice given remains appropriate and to discuss how to proceed in light of the valuation.',
            'You are aware that in taking a lifetime mortgage, the option to choose to opt for the local government deferment scheme in paying for care home costs is no longer available, so you would need to sell your home in this event. You are also aware that this will restrict your future estate planning options, for example, the option to leave each of your shares in your home into a life interest trust will no longer be possible, which may reduce the value of your estate or increase the amount of inheritance tax which may be payable.',
          ]),
          generateCustomHeader2('Planning Ahead'),
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Power of Attorney',
            [
              'I have confirmed that a Lasting Power of Attorney offers valuable security for you and your family should either of you fail in the future to be able to mentally or physically to control your own financial affairs. ',
              `${
                data.caseOverview.hasPowerOfAttorney
                  ? 'You have stated that you do have power attorney in place I would strongly advise you to consider reviewing this to ensure the relevant protection in place.'
                  : 'You have stated that you do not have a Power of Attorney in place I would strongly advise you to consider this course of action to put protection in place.'
              }.`,
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          data.estatePlanning.wouldLikePOAToBeOneOfObjectives
            ? generateCustomParagraph('You have stated that you wanted to make Power of Attorney one of your objectives.')
            : {},
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Beneficiaries',
            [
              'Releasing equity from your home will affect the residual value of your estate on death and the amount that could be passed on to your beneficiaries. We discussed this and your feelings regarding this matter have been recorded in the fact-find. We also discussed whether you have discussed your plans with your family and this was also recorded in the fact-find. You confirm you understand that releasing equity from your property value will reduce what you can pass onto any beneficiaries and you are happy to proceed on this basis.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          data.estatePlanning.hasWill ? generateSpaceParagraph() : {},
          data.estatePlanning.hasWill ? generatePinkCustomHeader3('Your Will') : {},
          data.estatePlanning.hasWill
            ? generateCustomParagraph(
                'You have confirmed you have a Will in place. However, we have discussed It is important that revisions are made whenever significant changes occur in your situation or intentions such as marriage/ divorce.',
              )
            : {},
          generateSpaceParagraph(),
          ...generateCustomSection(
            'Estate Planning',
            [
              'For your benefit and that of any of your family, it is well worth having a plan in place with regard to your estate, that being what you own, your assets and belongings and how these can benefit you or any family in the future. On this matter you told me that you are willing to seek advice.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          ...generateCustomSection(
            'Taxation',
            [
              'Inheritance Tax - I am not authorised to provide you with advice on Inheritance Tax matters or taxation in general and I recommend that you seek professional advice in this area. The decision to hold a plan of this type may also impact your estate (positively or negatively) with regards to Inheritance Tax.',
              'Tax from Income or Savings - Any income you generate from the capital released may attract income tax at your highest rate. Any interest paid on money deposited may also be subject to tax. I explained to you that holding funds on deposit will, over a longer term likely represent poor value for money, as the interest rate currently paid on a deposit account is likely to be less than the interest rate applicable to the lifetime mortgage.',
              'If you require advice on taxation in particular, please ask whether I can recommend a specialist in this area.',
            ],
            { headerColor: 'E668DF', headingLevel: 28 },
          ),
          generateCustomHeader2('Acceptance & Declaration'),
          generateCustomParagraph('Advisor Name:', { bold: true }),
          generateSpaceParagraph(),
          ...generateCustomSection('Client Lifetime Mortgage Suitability Letter Acceptance and Declaration', [
            `Dear ${data.customerFirst.firstName} ${secondCustomerExists ? `and ${data.customerSecond.firstName}` : ''}`,
            'Please ensure that you have read and understood the contents of the suitability letter and confirm your acceptance of the statements below by signing and dating at the bottom of the page. By signing and dating this declaration, we will regard this as your instruction to proceed with the Lifetime Mortgage arrangement accordingly.',
            'IMPORTANT - If you have any queries relating to the suitability letter or have any concerns with the statements below, then you must bring these concerns immediately to our attention in order that we can respond accordingly.',
          ]),
          generateCustomParagraph(
            'Please delete any of the below statements that do not apply. Please confirm you agree with the outstanding statements by marking with your initials.',
            { bold: true },
          ),
          ...generateBulletSection('', [
            `${weIOption} have fully understood the commitment we are making with regards this Mortgage / Loan.`,
            `${weIOption} confirm that we have received the mortgage providers' documentation.`,
            `${weIOption} confirm that we have received the providers' mortgage illustration.`,
            `${weIOption} understand that we are reducing any inheritance for our beneficiaries and that the estate may be reduced to nothing.`,
            `${weIOption} fully understand the Risks associated with this Lifetime Mortgage and the providers’ rights to repossess our property.`,
            `${weIOption} understand that by consolidating debts we are giving up equity in our property.`,
            `${weIOption} will not be informing our beneficiaries of our intention to take out a Lifetime Mortgage.`,
            `${weIOption} have discussed our plans to take out a Lifetime Mortgage with our beneficiaries and they will be signing this declaration.`,
            `${weIOption} have discussed our plans to take out a Lifetime Mortgage with our beneficiaries; however, they will not be signing this declaration.`,
            `${weIOption} have given permission for the beneficiaries detailed to receive a copy of this letter.`,
            `${weIOption} have given permission for the independent representative detailed to receive a copy of this letter.`,
            `${weAreIAmOption} fully aware of the allowances/benefits affected by taking out this Lifetime Mortgage, and have received and read the benefit report detailing our current entitlements.`,
            `${weAreIAmOption} aware of the loss to my current means tested benefits if I do not use the money as stated in the fact find and suitability report.`,
            `${weAreIAmOption} fully aware of the grant available to me, and that I have decided not to apply for this and understand fully the implications of not doing so.`,
            `${weIOption} fully understand that other options were available but having discussed these we have agreed that that the Lifetime Mortgage recommended is the most suitable option.`,
            `${weIOption} fully understand that interest will be charged on a rolled-up basis and the long term implications of this (linking to the previous comment about this being explained more comprehensively) `,
            `${weIOption} fully understand that there are no cancellation rights with this product, once the loan is completed.`,
          ]),
          generateCustomParagraph('Thank you and very kind regards,'),
          generateCustomParagraph('Advisors Name:'),
          generateCustomParagraph('Advisors Company Name:'),

          generateCustomParagraph('_____________________________'),
          generateCustomParagraph('Customer Signature:'),
          generateCustomParagraph(`Print Name: ${data.customerFirst.firstName}`),
          generateCustomParagraph('Date:'),

          ...(secondCustomerExists
            ? [
                generateCustomParagraph('_____________________________'),
                generateCustomParagraph('Customer Signature:'),
                generateCustomParagraph(`Print Name: ${data.customerSecond.firstName}`),
                generateCustomParagraph('Date:'),
              ]
            : []),

          generateCustomParagraph('_____________________________'),
          generateCustomParagraph('Beneficiary Signature (If applicable)'),
          generateCustomParagraph('Print Name:'),
          generateCustomParagraph('Date:'),
        ],
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '__________________________________________________________________________________', // Adjust the number of underscores to match the width of the page
                    color: '38033D', // Set the text color
                    size: 22,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                // This empty paragraph acts as a spacer
                children: [
                  new TextRun({
                    text: ' ',
                    size: 12, // Reduced size for less spacing
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Premier Equity Release Club | T: 0121 296 9987 | E: helpdesk@premiererc.co.uk\t', // Note the tab character
                    color: '38033D', // Set the text color
                    size: 22,
                    bold: true,
                  }),
                  // new TextRun({
                  //   text: 'Page {PAGE}', // This will be replaced by the current page number in Word
                  //   color: '38033D', // Set the text color
                  //   size: 22,
                  //   bold: true,
                  // }),
                ],
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: 9000, // Adjust as necessary for right alignment of the page number
                  },
                ],
              }),
            ],
          }),
        },
      },
    ],
  })

  return doc
}

export const handleGenerateDoc = async (
  data: any,
  disposableIncomeForNow: number,
  initialFundsTotalValue: number,
  recommendedProduct: IRecommendedProduct,
): Promise<void> => {
  // Create a new document
  const doc = await buildDoc(data, disposableIncomeForNow, initialFundsTotalValue, recommendedProduct)

  // Use a browser-compatible method to save the file
  Packer.toBlob(doc).then((blob) => {
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob)

    // Create a link element to download the blob
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = 'Suitability Report.docx'

    // Append the link to the document body and click it to trigger download
    document.body.appendChild(downloadLink)
    downloadLink.click()

    // Clean up
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(url)
  })
}
